import React, { UseState, UseEffect } from "react";
import { Link } from "react-router-dom";
import "../layouts/style.css"
// const [windowHeight, setwindowHeight] = UseState(window.innerHeight)
const NotFound = () => {
//   const handleResize = (e) => {
//     setwindowHeight({windowHeight: window.innerHeight})
// };
  
//   UseEffect(() => {
//     window.addEventListener('resize', handleResize);
    
//   },[])

// UseEffect(() => {
//   return () =>{
//     window.removeEventListener('resize', handleResize);
//   }
// },[])
// const minHeight = windowHeight - 150;
  return (
    <section class="page_404" >
        <div class="container">
          <div class="row">	
          <div class="col-sm-12 ">
          <div class="col-sm-10 col-sm-offset-1  text-center">
          <div class="four_zero_four_bg">
            {/* <h1 class="text-center ">404</h1> */}
          
          
          </div>
          
          <div class="contant_box_404">
          <h3 class="h2">
          Look like you're lost
          </h3>
          
          <p>the page you are looking for not available !</p>
          
        <Link to={{pathname:'/reports'}} class="link_404">Go to Home</Link>
        </div>
          </div>
          </div>
          </div>
        </div>
  </section>
  )
}

export default NotFound