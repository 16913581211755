import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { getReportsHome } from '../../services/reports';
import { Row, Col, Carousel, Modal, Popover, Avatar } from 'antd';
import { ExclamationCircleOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons"
import './reports.scss'
import NoRecords from '../NoRecords';
import { spinner } from '../../shared/spinner';
import Cookies from 'js-cookie';
import { replaceURLAddress } from '../../config';
import { logout } from '../../services/auth';

const getReports = async () => {
  let res = await getReportsHome()
  return res;
}

const ReportsLanding = () => {

  const [visible, setVisible] = useState(false);
  let userInitials;
  const [userName, setUserName] = useState();
  const confirm = () => {
    setVisible(false);
    Modal.confirm({
      className: 'logout-popup',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to Logout?',
      okText: 'Ok',
      centered: true,
      cancelText: 'Cancel',
      onOk: () => {
        logout();
        Cookies.remove('token');
        window.location.replace(process.env.REACT_APP_API_REPLACE_ENDPOINT);
        // window.location.replace(replaceURLAddress);
      },
      maskClosable: true
    });
  };
  const [reports, setReports] = useState([]);
  const [apiInProgress, setApiInProgress] = useState(true);
  const [slide, setSlide] = useState(1);
  const slider = useRef();

  const fetchReports = useCallback(() => {
    setApiInProgress(true);
    getReports()
      .then(res => {
        return res.json()
      })
      .then(res => {

        console.log('result deeref', res)
        Cookies.set('user-info', (JSON.stringify(res.user_info)), { sameSite: 'Lax' });
        let userDetail = Cookies.get('user-info');
        userDetail = userDetail && JSON.parse(userDetail)
        localStorage.setItem('reports', JSON.stringify(res.reports_dashboard));

        userInitials = userDetail.first_name.slice(0, 1) + userDetail.last_name.slice(0, 1);
        setUserName(userInitials);
        return res;
      })
      .then(({ reports_dashboard }) => {
        if (Array.isArray(reports_dashboard)) {
          setReports(reports_dashboard);


        }

        setApiInProgress(false)
      })

      .catch((err) => {
        console.log('err', err)
        setApiInProgress(false)
      })
  }, []);

  useEffect(() => {
    fetchReports();
  }, [fetchReports])

  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };
  const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };
  const next = () => {
    slider.current.next()
  }
  const previous = () => {
    slider.current.prev()
  }

  const content = (
    <div class="dropdwon-cus">
      <p onClick={confirm}>Logout</p>
    </div>
  );
  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };


  return (

    <div className="site-card-wrapper" style={{ backgroundImage: 'url(../images/Banner.jpg)', height: '100vh', backgroundRepeat: 'no-repeat', backgroundSize: '100%' }}>
      <section className="page-contain">
        <div className='main-content'>
          <header className='headLogo'>
            <img src="../../images/logo.png" alt="" />

            <Popover overlayClassName="user-dropdown" visible={visible} content={content} onVisibleChange={handleVisibleChange} trigger="click" >
              <Avatar style={{ backgroundColor: '#05AFF0', position: 'absolute', right: '20px', top: '10px' }} size="large" >
                {userName}
              </Avatar>
            </Popover>
          </header>
          <img src="../../images/it-logo-white.png" alt="" style={{ marginTop: '6%' }} />
          <br />
          <br />
          {/* <div style={{width:'950px', margin: '40px auto 0'}}> */}
          {apiInProgress ? spinner() : (
            // <div style={{display:'flex', flexWrap:'wrap', paddingLeft:0,}}> 
            <div class="report-slider">
              {reports.length > 2 && <LeftOutlined onClick={previous} />}
              {/* <Icon type="left-circle"  /> */}
              <Carousel className={reports.length < 0 ? 'no-record-slider' : ''} ref={ref => { console.log(ref); slider.current = ref }} afterChange={onChange} slidesToShow={2} infinite={false}>
                {reports.length > 0 ?
                  reports.map(rep =>
                    <Link to={{ pathname: `/${rep.report.report_name}/top-categories` }} className="data-card">
                      {/* <h3>{rep.report.report_display_name}</h3> */}
                      <div class="box-info">
                        <div class="info-list">
                          <div class="info-icon">
                            <img src="../../images/cat.svg" alt="" />
                          </div>
                          <div className={rep.it_data.categories.length <= 0 ? 'zero-line' : 'info-right'}>
                            <h5 >Categories: <span> <span>{rep.it_data.categories.length > 0 ? rep.it_data.categories.length : ''}</span></span></h5>
                            <div className='one-line cat-list'>

                              {rep.it_data.categories.length > 0 ? rep.it_data && rep.it_data.categories.map((item, i) => <span class="tag-cus"> {i > 0 && " "}{item}</span>) : ''}
                              <br></br>

                            </div>
                          </div>
                        </div>
                        <div class="info-list">
                          <div class="info-icon">
                            <img src="../../images/quaters.svg" alt="" />
                          </div>
                          <div className={rep.it_data.quarter.length <= 0 ? 'zero-line' : 'info-right'}>
                            <h5>Quarters:<span>{rep.it_data.quarter.length}</span></h5>
                            <div class="one-line cat-list">
                              {rep.it_data.quarter ? rep.it_data.quarter.map((item, i) => <span class="tag-cus"> {i > 0 && " "}{item}</span>) : ['']}
                            </div>
                          </div>
                        </div>
                        <div class="info-list">
                          <div class="info-icon">
                            <img src="../../images/user.svg" alt="" />
                          </div>
                          <div className={rep.it_data.user_count.length <= 0 ? 'zero-line' : 'info-right'}>
                            <h5>Users:<span>{rep.it_data.user_count}</span></h5>
                          </div>
                        </div>
                        <span className="link-text" class="box-btn">
                          Open Report
                        </span>
                      </div>
                    </Link>




                    // </Col>
                  ) : NoRecords()}
                {/* </Row> */}
              </Carousel>
              {reports.length > 2 && <RightOutlined onClick={next} />}
              {/* <Icon type="right-circle" onClick={next} /> */}
            </div>
            // </div>    
          )}
          {/* </div> */}

        </div>
      </section>
      {/* 
      <section className="page-contain">
            <div className='main-content'>
                <header className='headLogo'>
                    <img src="../../images/logo.png" alt="" />
                </header>

                    <img src="../../images/it-logo.png" alt="" />
                    <br /><br />
                    <Row >
                    
                    {reports.map(rep => {
                        return (
                            <Col span={6}>
                            <Link  to={{ pathname:`/${rep.report.report_name}/top-categories`}} className="data-card">
                            <h3>{rep.report.report_name.toUpperCase().replace(/_/g, " ")}</h3>
                            <span class="link-text">
                            <br />
                            Proceed
                            <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8631 0.929124L24.2271 7.29308C24.6176 7.68361 24.6176 8.31677 24.2271 8.7073L17.8631 15.0713C17.4726 15.4618 16.8394 15.4618 16.4489 15.0713C16.0584 14.6807 16.0584 14.0476 16.4489 13.657L21.1058 9.00019H0.47998V7.00019H21.1058L16.4489 2.34334C16.0584 1.95281 16.0584 1.31965 16.4489 0.929124C16.8394 0.538599 17.4726 0.538599 17.8631 0.929124Z" fill="#1890ff"/>
                        </svg>
                            </span>
                        </Link>
                    </Col>
                        )  
                    })}
                    </Row>
                </div>
            </section> */}
    </div>
  );
};

export default ReportsLanding;
