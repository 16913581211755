import React, { useEffect, useState } from "react";
import { Layout, Menu, Button } from "antd";
import {
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined,
  GlobalOutlined,
  EnvironmentOutlined,
  MenuUnfoldOutlined,
  AppstoreOutlined,
  CodeSandboxOutlined


} from "@ant-design/icons";


import { Link, useLocation, useParams } from "react-router-dom";

const { Sider } = Layout;

const { SubMenu } = Menu;

const AppSidebar = () => {
const location = useLocation();
const report_year = window.location.pathname.split('/')[1]
const [collapsed, setCollapsed] = React.useState(false);
let filterSelected = location.state ? location.state.filterBasedOn :'top-category'
const [buttonClass, setButtonClass] = useState('top-category');
const [openKeys, setOpenKeys] = useState('sub1')

useEffect(() => {
  if(location.state){
    setButtonClass(location.state.filterBasedOn)
    // alert(123)
    // const onSubmenuChange = ( itm ) => {
    //   let openedKeys = itm.pop();
    //   setOpenKeys(openedKeys)
    //   console.log("opend ksy", openKeys)
    // }
    console.log(filterSelected.includes)
    
    if(filterSelected.includes('location')){
      console.log("Prafull", filterSelected.includes)
      setOpenKeys(['sub2'])
    }
  }
}, [filterSelected])

const onSubmenuChange = ( itm ) => {
  console.log(itm)
  let openedKeys = itm.pop();
  setOpenKeys(openedKeys)
  console.log("opend ksy", openKeys)
}

return (
    <Sider 
    onCollapse={() => setCollapsed(!collapsed)}
    collapsible  
    collapsed={collapsed}
    style={{      
      height: '100vh',      
      left: 0,
      top: 0,
      bottom: 0,
      position: 'sticky'
    }}
    className="site-layout-background">
 <div className="logo" />
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={`1::${buttonClass}`}
        defaultOpenKeys={["sub1"]}
        onOpenChange = {onSubmenuChange}
        openKeys={[openKeys]}
        style={{ height: "100%", borderRight: 0 }}
      >
        <SubMenu key="sub1" title=" Top Categories" icon={<AppstoreOutlined />} SelectedKeys={[filterSelected]} >
          
           <Menu.Item key={`1::${buttonClass}`} className={buttonClass == 'top-category' ? 'ant-menu-item-selected': ''}> <Link to={{ pathname:`/${report_year}/top-categories`}} state={{filterBasedOn:'top-category'}}/> Categories</Menu.Item>

           
          <Menu.Item key={`2::${buttonClass}`} className={buttonClass == 'category-time'?'ant-menu-item-selected':'' } > <Link to={{ pathname:`/${report_year}/categories`}}  state={{ filterBasedOn: "category-time" }}/> Filter by Time</Menu.Item>
          <Menu.Item key={`3::${buttonClass}`} className={buttonClass == 'category-retailer'?'ant-menu-item-selected':''}>  <Link to={{ pathname:`/${report_year}/categories`}}  state={{ filterBasedOn: "category-retailer" }} />Filter by Retailer</Menu.Item>
          <Menu.Item key={`4::${buttonClass}`}  className={buttonClass == 'category-activity'?'ant-menu-item-selected':''}>   <Link to={{ pathname:`/${report_year}/categories`}}   state={{ filterBasedOn: "category-activity" }} />Filter by Activity</Menu.Item>
          <Menu.Item key={`5::${buttonClass}`}  className={buttonClass == 'category-location'?'ant-menu-item-selected':''}>   <Link to={{ pathname:`/${report_year}/categories`}}  state={{ filterBasedOn: "category-location" }} />Filter by Location</Menu.Item> 

        </SubMenu>
       
        <SubMenu key="sub2" title="Locations" icon={<EnvironmentOutlined />}>

        <Menu.Item key={`6::${buttonClass}`}    className={buttonClass == 'location-landing' ? 'ant-menu-item-selected': ''} ><Link to={{pathname:`/${report_year}/locationlanding`}} state={{filterBasedOn:'location-landing'}} />Locations</Menu.Item>

          <Menu.Item  key={`7::${buttonClass}`} className={buttonClass == 'location-timer' ? 'ant-menu-item-selected': ''} > <Link to={{ pathname:`/${report_year}/locations`}} state={{ filterBasedOn: "location-timer" }}  />Filter by Time</Menu.Item>
          <Menu.Item key={`8::${buttonClass}`}  className={buttonClass == 'location-retailer' ? 'ant-menu-item-selected': ''} >  <Link to={{ pathname:`/${report_year}/locations`}} state={{ filterBasedOn: "location-retailer" }} /> Filter by Retailer</Menu.Item>
          <Menu.Item key={`9::${buttonClass}`}  className={buttonClass == 'location-category' ? 'ant-menu-item-selected': ''} >  <Link to={{ pathname:`/${report_year}/locations`}} state={{ filterBasedOn: "location-category" }} />Filter by Category</Menu.Item>
        </SubMenu>
        <SubMenu key="sub3" icon={<LaptopOutlined />} title="Impulse Drivers">
          <Menu.Item key={`10::${buttonClass}`} className={buttonClass == 'driver-timer' ? 'ant-menu-item-selected': ''} >   <Link to={{ pathname:`/${report_year}/drivers`}}  state={{ filterBasedOn: "driver-timer" }}  />Filter by Time</Menu.Item>
          <Menu.Item key={`11::${buttonClass}`}  className={buttonClass == 'driver-retailer' ? 'ant-menu-item-selected': ''} > <Link to={{ pathname:`/${report_year}/drivers`}} state={{ filterBasedOn: "driver-retailer" }}  />Filter by Retailer</Menu.Item>
          <Menu.Item key={`12::${buttonClass}`}  className={buttonClass == 'driver-category' ? 'ant-menu-item-selected': ''} >  
          <Link to={{ pathname:`/${report_year}/drivers`}} state={{ filterBasedOn: "driver-category" }}  />
          Filter by Category</Menu.Item>
          
        </SubMenu>
        <SubMenu key="sub4" icon={<CodeSandboxOutlined />} title="Displays">
          <Menu.Item key={`13::${buttonClass}`} className={buttonClass == 'displays' ? 'ant-menu-item-selected': ''}>
            <Link to= {{ pathname:`/${report_year}/displays`}} state={{ filterBasedOn: "displays" }}  />
            Top Displays</Menu.Item>
          <Menu.Item key={`14::${buttonClass}`}><Link to= {{ pathname:`/${report_year}/displaystwo`}} />Top Q2 Displays</Menu.Item>
          <Menu.Item key={`15::${buttonClass}`}><Link to={{ pathname:`/${report_year}/displaysthree`}}/>Top Q3 Displays</Menu.Item>
          <Menu.Item key={`16::${buttonClass}`}><Link to={{ pathname:`/${report_year}/displaysfour`}} />Top Q4 Displays</Menu.Item>
        </SubMenu>
      
      </Menu>
    </Sider>
  );
};

export default AppSidebar;
