import { useEffect } from "react";
import { getCategoriesHome } from "../../services/top-categories";
import Cookies from 'js-cookie';

const getReportsAccess = async () => {
    // const res = await getCategoriesHome();
    // res=[]
    // console.log('res', res)
}


const VerifyToken = () => {
        let tokenValue =  window.location.pathname.split('/')[2]
        console.log("token value,", tokenValue)
        Cookies.set('token', tokenValue)
    useEffect(() => {
        if(tokenValue){
            window.location = '/reports'
        }
    }, [tokenValue])

    return (
        <></>
    )
};

export default VerifyToken;
