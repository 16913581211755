const { crudData } = require('../../utils/apiService');

const getReportsHome = () => {
  const res = crudData('/users/sso/', 'GET', {}, {}, {});
  return res;
};



export{
    getReportsHome
}
