import { Layout } from "antd";
import React, { Suspense } from "react";

import routes from "../../routes";
import { Navigate, Route, Routes } from "react-router-dom";
import ReportsLanding from "../../modules/reports-landing";
const { Content } = Layout;


const AppContent = () => {


  return (
    <Content
      className="site-layout-background"
      style={{
        padding: 24,
        margin: 0,
        minHeight: 200,
        
      }}
    >
      <Suspense>
        <Routes>
          {routes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element />}
                />
              )
            );
          })}
          <Route path="/" element={<Navigate to="/reports" replace />} />
          <Route path="/terms" element={<Navigate to="/terms" replace />} />
          {/* <Route path="/privacy-policy" element={<Navigate to="/privacy-policy" replace />}/> */}
     
        </Routes>
      </Suspense>
    </Content>
  );
};

export default AppContent;
