
const createColumnsForTable = (data) => {
    const allColumns = Object.keys(data[0]);
    const antDColumns = allColumns.map((columnName, index) => {
        return {
            title: columnName,
            dataIndex: columnName,
            key: index,
            align: columnName !== 'CSD' ? 'center' : '',
            width: columnName.length * 10,
            sorter: ((a, b) => {
                let excludePercentage;
                let isNumber;
                if (typeof a[columnName] == 'string' && a[columnName].indexOf('%') !== -1) {
                    excludePercentage = a[columnName].replace(/\%/g, '');
                    isNumber = !isNaN(parseFloat(excludePercentage)) && isFinite(excludePercentage);
                }
                if (isNumber) {
                    return parseInt(a[columnName]) - parseInt(b[columnName])
                }
                else if (typeof a[columnName] == 'string' && a[columnName] !== '-' && typeof b[columnName] == 'string') {
                    return a[columnName].localeCompare(b[columnName])

                }
                else if (typeof a[columnName] == 'number' && a[columnName] !== '-' && typeof b[columnName] == 'number') {
                    return parseInt(a[columnName]) - parseInt(b[columnName])

                }
                else if (a[columnName] == '-' || a[columnName] == '<1%') {
                    return -1;
                }

                return 1
            }),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: columnName == 'Aggregate' ? 'ascend' : ''

        }
    })
    return antDColumns;
}

module.exports = {
    createColumnsForTable,
}
