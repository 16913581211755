import { Select, Table, Spin, PageHeader, Button, Breadcrumb, Empty, Tooltip } from 'antd';
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { getCategories, getReportCategoriesByBrand, getYears } from '../../../services/category-reports';
import { createColumnsForTable } from '../category-reports-utils';
import './style.scss'
import {
    FilterOutlined,
    CalendarOutlined,
    ArrowLeftOutlined,
    SearchOutlined,
    ArrowRightOutlined,
} from "@ant-design/icons";
import Icon from '@ant-design/icons/lib/components/AntdIcon';
import Cookies from 'js-cookie';
import NoRecords from '../../NoRecords';
import NotFound from '../../../shared/not-found';
import ReactGA from "react-ga4";

const { Option } = Select;
//   const yearsOptions = ['H1 2022', 'H2 2021]
const BrandReport = () => {

    let userDetail = Cookies.get('user-info');
    userDetail = JSON.parse(userDetail)
    const checkCSA = userDetail.user_permissions.platform_role_id;

    const [reportYear, setReportYear] = useState(window.location.pathname.split('/')[1]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState();
    const [brandData, setBrandData] = useState([]);
    const [selectedYear, setSelectedYear] = useState();
    const [yearOptions, setYearOptions] = useState()
    const [tableColumns, setTableColumns] = useState();
    const [loading, setLoading] = useState(true);
    const [disableTimeFilter, setDisableTimeFilter] = useState();
    const [yearsOptions, setYearsOptions] = useState([]);
    const [tableApiInPrgress, setTableApiInPrgress] = useState(false)
    const [defaultSelectedYear, setDefaultSelectedYear] = useState()

    useEffect(() => {
        document.title = "Category Reports IT"
    }, [])

    const loadYears = async () => {
        const receivedYear = await getYears(reportYear).then(res => {
            if (res.sorting_order) {
                setYearsOptions(res.sorting_order)
                setSelectedYear(res.sorting_order[0])
            }
            return res
        });
        const years = receivedYear.half_years;
        setYearsOptions(years)
        setSelectedYear(years[0])
    }





    const handleCategoryChange = async () => {
        // let createdColumns;

        // let filteredreceivedBrandData
        // setLoading(true)
        // const receivedBrandData = await getReportCategoriesByBrand(reportYear, selectedCategory).then(res => {
        //     // if(res.sorting_order){ 
        //     //     setYearsOptions(res.sorting_order)
        //     //     setSelectedYear(res.sorting_order[0])
        //     // }

        //     return res.result
        // });

        // filteredreceivedBrandData = receivedBrandData && receivedBrandData.filter(item => item.Time == selectedYear);

        // if (filteredreceivedBrandData && filteredreceivedBrandData.length > 0) {
        //     createdColumns = filteredreceivedBrandData && createColumnsForTable(filteredreceivedBrandData);
        // }
        // else {
        //     setLoading(false)
        // }
        // if (typeof (filteredreceivedBrandData) !== 'undefined') {
        //     setBrandData(filteredreceivedBrandData);
        // }
        // setTableColumns(createdColumns);
        // setLoading(false)



        let currentSelectedYear;
        setTableApiInPrgress(true)
        if (selectedCategory) {
            getReportCategoriesByBrand(reportYear, selectedCategory).then((response) => {
                const receivedBrandData = response.result
                const receivedYears = response.sorting_order;
                if ((receivedYears && receivedYears.length > 0) && !selectedYear) {
                    setYearsOptions(receivedYears)
                    setDefaultSelectedYear(receivedYears[0])
                }
                else {
                    // setSelectedYear(selectedYear)
                }

                if (!selectedYear) {
                    currentSelectedYear = receivedYears?.[0];
                } else {
                    currentSelectedYear = selectedYear;
                }

                const filteredreceivedBrandData = (receivedBrandData || []).filter(item => item.Time == currentSelectedYear);
                let createdColumns;
                if (filteredreceivedBrandData.length > 0) {
                    createdColumns = createColumnsForTable(filteredreceivedBrandData);
                }
                if (typeof (filteredreceivedBrandData) !== 'undefined') {
                    setBrandData(filteredreceivedBrandData);
                }
                setTableColumns(createdColumns);
                setTableApiInPrgress(false);

            }).catch((error) => {
                console.log('error :>> ', error);
            }).finally(() => {

            });
        }

    }



    const onInit = async () => {
        // setBrandData([]);
        // loadYears();
        // const receivedCategories = await getCategories(reportYear)
        //     .then(res => {
        //         return res.result
        //     });

        // if (receivedCategories.length > 0) {
        //     setCategories(receivedCategories);
        //     setSelectedCategory(receivedCategories[0]);
        //     selectedCategory && handleCategoryChange()
        // }
        // else {
        //     setLoading(false)
        // }

        // loadY    ears();
        getCategories(reportYear)
            .then((res) => {
                const receivedCategories = res.result;
                if (receivedCategories?.length > 0) {
                    setCategories(receivedCategories);
                    setSelectedCategory(receivedCategories[0]);
                    selectedCategory && handleCategoryChange();
                }
            })
            .catch((error) => {
                console.log("error :>> ", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        onInit()
    }, [])

    useEffect(() => {
        handleCategoryChange();
    }, [selectedCategory, selectedYear])

    // useEffect( () => {
    //     if(!tableApiInPrgress && brandData?.length > 0){
    //         setIsData(true)
    //     }

    // }, [tableApiInPrgress, brandData])

    const handleCategory = (selected_category) => {
        // setLoading(true);
        setSelectedCategory(selected_category)
        // setLoading(false)

        /*setting activity on google analytics*/
        const category = "Categories_Reports_Top_Brands_Select_Category";
        const action = `CR_TB/${selectedYear ? selectedYear : ''}${selectedYear && selected_category ? '/' : ''}${selected_category}`;
        const label = "Test_Categories_Reports_Top_Brands_Select_Category";
        if (selected_category) {
            ReactGA.event({
                category, action, label
            })
        }
    }
    let locale = {
        emptyText: (
            <Empty description="You may not have subscribed to this section of the report. Please try changing filters such as time periods.
            Feel free to reach your NAILBITER Client Service Contact if you need any support."/>
        )
    };
    const handleYear = (year) => {
        if (year) {
            console.log("year", year)
            setSelectedYear(year)
            setDefaultSelectedYear(year)
        }
        else {
            setSelectedYear(yearOptions[0]);
            setDefaultSelectedYear(year)
        }
        /*setting activity on google analytics*/
        const category = "Categories_Report_Top_Brands_Category_Period_Filter";
        const action = `CR_TB/${year} ${selectedCategory ? '/' : ''} ${selectedCategory ? selectedCategory : ''}`;
        const label = "Test_Categories_Report_Top_Brands_Category_Period_Filter"
        if (year) {
            ReactGA.event({
                category, action, label
            })
        }
    }

    return (
        <>
            {checkCSA !== 2 ? <>
                <div className="site-page-header-ghost-wrapper" >

                    {/* <Breadcrumb>
           <Breadcrumb.Item><Link to={{ pathname:`/reports`}}>Home</Link></Breadcrumb.Item>
           <Breadcrumb.Item><Link to={{ pathname:`/${reportYear}/category-reports`}}>categories-reports</Link></Breadcrumb.Item>
           <Breadcrumb.Item>
               <Breadcrumb.Item>Top Brands by Category</Breadcrumb.Item>
           </Breadcrumb.Item>
       </Breadcrumb> */}
                    {/* <PageHeader
           title="Top Brands by Category"
           
           extra={[
           <Link to={{ pathname:`/${reportYear}/top-categories`}} ><Button key="1" icon={<CalendarOutlined />} >Top Categories</Button> </Link>,
           <Link to={{ pathname:`/${reportYear}/locationlanding`}}  > <Button key="2"  icon={<CalendarOutlined />}>Locations</Button></Link>   ,
           <Link to={{ pathname:`/${reportYear}/driversLanding`}}  >  <Button key="3" icon={<CalendarOutlined />}>Impulse Driver</Button> </Link>,
           <Link to={{ pathname:`/${reportYear}/displays`}}  > <Button key="4"  icon={<CalendarOutlined />}>Displays</Button></Link>,
           checkCSA !== 2 ?  <Link  to={{ pathname:`/${reportYear}/category-reports`}}  state={{ filterBasedOn: "category-reports" }}> <Button  type="primary" key="5"icon={<CalendarOutlined />}>Category Reports</Button></Link> : <></>,
           ]}
       >
       </PageHeader> */}

                    <div class="page-title">
                        <h1>Top Brands</h1>
                        <Breadcrumb>
                            <Breadcrumb.Item><Link class="back-arrow" to={{ pathname: `/${reportYear}/category-reports` }}><Button type='primary' shape="round">Back</Button></Link></Breadcrumb.Item>
                        </Breadcrumb>
                    </div>

                </div>
                {/* <div class="flex gx-mt-3 gx-flex-row-reverse gx-d-flex"> */}
                <div >
                    <div style={{ float: 'right', margin: '15px 0' }}>

                        <Select
                            showSearch
                            allowClear
                            placeholder="select year"
                            optionFilterProp="children"
                            suffixIcon={<FilterOutlined />}
                            onChange={(year) => handleYear(year)}
                            //defaultValue={selectedCategory}
                            value={defaultSelectedYear}
                            style={{ width: 208, marginRight: 14 }}
                        //    className="gx-mr-3"
                        >
                            {yearsOptions && yearsOptions.map((item, i) => <Option value={item} key={i}> {item}</Option>)}
                        </Select>
                        <Select
                            showSearch
                            allowClear
                            placeholder="Category"
                            optionFilterProp="children"
                            suffixIcon={<FilterOutlined />}
                            onChange={(cat) => handleCategory(cat)}
                            // defaultValue={selectedCategory}
                            value={selectedCategory}
                            style={{ width: 271, }}
                        >
                            {categories && categories.map((item, i) => <Option value={item} key={i}> {item}</Option>)}
                        </Select>

                    </div>
                    {/* {tableApiInPrgress ? <><Spin size='large' style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', minHeight: '450px', alignItems: 'center', width: '100%' }} /> </> : <>
                        {tableColumns  && brandData ? 

                            <div class="" >
                                {loading ? <div >
                                    <Spin size='large' className='abc' style={{ display: 'flex', width: '100%', justifyContent: 'center', alignContent: 'center', minHeight: '450px', alignItems: 'center', }} /> </div> :
                                    brandData ? <Table
                                        // scroll={{ x: 80 }}
                                        style={{ tableLayout: 'auto', width: '100%', paddingTop: '10px' }}
                                        className="tableStructure "
                                        key={tableColumns}
                                        columns={tableColumns}
                                        dataSource={brandData}
                                        loading={{ indicator: <Spin size="large" />, spinning: loading }}
                                        pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }} /> : <div class="no-record-found" style={{ width: '100%' }}><img src="../../images/no-data.png" /><h4 class="nodata-dis">You may not have subscribed to this section of the report. Please try changing filters such as time periods.
                                            <br></br> Feel free to reach your NAILBITER Client Service Contact if you need any support</h4></div>}

                            </div> : <div class="no-record-found" style={{ width: '100%' }}><img src="../../images/no-data.png" /><h4 class="nodata-dis">You may not have subscribed to this section of the report. Please try changing filters such as time periods.
                                            <br></br> Feel free to reach your NAILBITER Client Service Contact if you need any support</h4></div>

                        } </>} */}


                    {tableApiInPrgress ?
                        <Spin size='large' style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', minHeight: '450px', alignItems: 'center', width: '100%' }} />
                        : brandData.length ?
                            <Table
                                style={{ tableLayout: 'auto', width: '100%' }}
                                className="tableStructure "
                                key={tableColumns}
                                columns={tableColumns}
                                dataSource={brandData}
                                loading={{ indicator: <Spin size="large" />, spinning: loading }}
                                scroll={{ x: "max-content", y: window.innerHeight > 400 ? window.innerHeight - 200 : 400 }}
                                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }} />
                            :

                            <div class="no-record-found" style={{ width: '100%' }}>
                                <img src="../../images/no-data.png" />
                                <h4 class="nodata-dis">You may not have subscribed to this section of the report. Please try changing filters such as time periods.
                                    <br></br> Feel free to reach your NAILBITER Client Service Contact if you need any support
                                </h4>
                            </div>
                    }
                </div>
                {/* {!loading && brandData   ?  <div class="no-record-found" style={{width:'100%'}}><img src="../../images/no-data.png" /><h4 class="nodata-dis">You may not have subscribed to this section of the report. Please try changing filters such as time periods.
<br></br> Feel free to reach your NAILBITER Client Service Contact if you need any support</h4></div>:<></>}  */}
            </> : <NotFound />}



        </>

    );
}

export default BrandReport;
