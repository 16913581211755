import { crudData } from "../../utils/apiService";

const { baseAddress } = require("../../config")


const refresh = async () => {
  fetch(process.env.REACT_APP_API_ENDPOINT + '/')
  //fetch(baseAddress+'/')
}


const logout = async () => {
  const res = await crudData('/users/logout/', 'POST', {}, {}, {})
  return res;
};



export { refresh, logout }