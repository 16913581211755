import "./App.css";
import React, { Component, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DefaultLayout from "./layouts/DefaultLayout";
import ReportsLanding from "./modules/reports-landing";
import VerifyToken from "./modules/verify-token";
import TermsAndConditions from "./modules/terms-and-conditions";
import PrivacyPolicy from "./modules/category-reports/privacy-policy";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Suspense>
          <Routes>
            <Route path="/verify-token/:token" element={<VerifyToken />} />
            <Route path="/reports" element={<ReportsLanding />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            {/* <Route  path="/privacy-policy" element={<PrivacyPolicy/>} /> */}
            <Route path="/privacy-policy.html" element={<PrivacyPolicy />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
