import React from "react";
import BrandReport from "./modules/category-reports/category-reports-brands";
import DriverReport from "./modules/category-reports/category-reports-drivers";
import LocationReport from "./modules/category-reports/category-reports-location";
import TermsAndConditions from "./modules/terms-and-conditions";


// const Dashboard = React.lazy(() => import("./modules/dashboard/index"));
const Category = React.lazy(() => import("./modules/category/index"));
const Categorieslanding = React.lazy(() => import("./modules/categories-landing"));
const Locationlanding = React.lazy(() => import("./modules/location-landing"));
const Locations = React.lazy(() => import("./modules/locations/index"));
const Drivers = React.lazy(() => import("./modules/drivers/index"));
const Displays = React.lazy(() => import("./modules/displays"));
const Displaystwo = React.lazy(() => import("./modules/displays/displaystwo"));
const DisplaysOne = React.lazy(() => import("./modules/displays/displayone"));
const Displaysq2 = React.lazy(() => import("./modules/displays/displayQ2"));
const NotFound = React.lazy(() => import("./shared/not-found"));
const DriversLanding = React.lazy(() => import("./modules/drivers-landing/index"));
const Displaysthree = React.lazy(() =>
  import("./modules/displays/displaysthree")
);
const Displaysfour = React.lazy(() => import("./modules/displays/displaysfour"));
const displayQ32022 = React.lazy(() => import("./modules/displays/displayQ32022"));
const categoryReportsDisplay = React.lazy(() => import("./modules/category-reports/category-reports-display/index"));
const categoryReportsLanding = React.lazy(() => import("./modules/category-reports/category-report-landing/index"));
const Terms = React.lazy(() => import("./modules/terms-and-conditions/index"));
// const Reports= React.lazy(() => import("./modules/reports-landing/index") )

const routes = [
  // { path: "/", exact: true, name: "Routes" },
  // { path: "/dashboard", name: "Dashboard", element: Dashboard },
  {
    path: "/:report_year/top-categories/",
    name: "Categorieslanding",
    element: Categorieslanding,
    exact: true,
  },
  {
    path: "/:report_year/top-categories/time",
    name: "Category",
    element: Category,
    exact: true,
  },

  {
    path: "/:report_year/top-categories/retailer",
    name: "Category",
    element: Category,
    exact: true,
  },
  {
    path: "/:report_year/top-categories/activity",
    name: "Category",
    element: Category,
    exact: true,
  },
  {
    path: "/:report_year/top-categories/location",
    name: "Category",
    element: Category,
    exact: true,
  },


  // {
  //   path: "/:report_year/categories",
  //   name: "CategoryByTime",
  //   element: CategoryByTime,
  //   exact: true,
  // },
  // {
  //   path: "/:report_year/locationlanding",
  //   name: "Locationlanding",
  //   element: Locationlanding,
  //   exact: true,
  // },
  {
    path: "/:report_year/locations/time",
    name: "Locationlanding",
    element: Locationlanding,
    exact: true,
  },

  {
    path: "/:report_year/locations/retailer",
    name: "Locationlanding",
    element: Locationlanding,
    exact: true,
  },

  {
    path: "/:report_year/locations",
    name: "Locationlanding",
    element: Locationlanding,
    exact: true,
  },
  {
    path: "/:report_year/drivers",
    name: "DriversLanding",
    element: DriversLanding,
    exact: true,
  },
  {
    path: "/:report_year/drivers/time",
    name: "Drivers",
    element: Drivers,
    exact: true,
  },

  {
    path: "/:report_year/drivers/retailer",
    name: "Drivers",
    element: Drivers,
    exact: true,
  },

  {
    path: "/:report_year/displays",
    name: "Displays",
    element: Displays,
    exact: true
  },
  {
    path: "/:report_year/displays/:year_qtr",
    name: "Displaysone",
    element: DisplaysOne,
    exact: true,
  },
  {
    path: "/:report_year/displays/Q2_2021",
    name: "Displaysone",
    element: DisplaysOne,
    exact: true,
  },
  {
    path: "/:report_year/displays/Q4_2022",
    name: "Displaysone",
    element: DisplaysOne,
    exact: true,
  },
  {
    path: "/:report_year/displays/Q3_2021",
    name: "Displaysone",
    element: DisplaysOne,
    exact: true,
  },
  {
    path: "/:report_year/displays/Q4_2021",
    name: "Displaysone",
    element: DisplaysOne,
    exact: true,
  },
  /*need to delete
  {
    path: "/:report_year/displaystwo",
    name: "Displaystwo",
    element: Displaystwo,
    exact: true,
  },
 {
    path: "/:report_year/displays/Q2_2022",
    name: "Displaysone",
    element: Displaysq2,
    exact: true,
  },
  {
    path: "/:report_year/displays/Q3_2022",
    name: "displayQ32022",
    element: displayQ32022,
    exact: true,
  },

  {
    path: "/:report_year/displaysthree",
    name: "Displaysthree",
    element: Displaysthree,
    exact: true,
  },
  {
    path: "/:report_year/displaysfour",
    name: "Displaysfour",
    element: Displaysfour,
    exact: true,
  },
*/
  {
    path: "/:report_year/category-reports",
    name: "CategoryReportsLanding",
    element: categoryReportsLanding,
    exact: true,
  },
  {
    path: "/:report_year/category-reports/displays",
    name: "categoryReportsDisplay",
    element: categoryReportsDisplay,
    exact: true,
  },
  {
    path: "/:report_year/category-reports/locations",
    name: "LocationReport",
    element: LocationReport,
    exact: true,
  },
  {
    path: "/:report_year/category-reports/drivers",
    name: "DriverReport",
    element: DriverReport,
    exact: true,
  },
  {
    path: "/:report_year/category-reports/brands",
    name: "BrandReport",
    element: BrandReport,
    exact: true,
  },
  {
    path: "*",
    name: "NotFound",
    element: NotFound,
    // exact: true,
  },

  // {
  //   path: "/terms",
  //   name: "Terms",
  //   element: TermsAndConditions,
  //   // exact: true,
  // },

  // { path: "/verify-token/:token", name: "Reports", element: Reports },

];

export default routes;
