import React, { useEffect, useState } from 'react';
import { getCategories, getReportCategoriesByDrivers, getYears } from '../../../services/category-reports';
import { Chart } from "react-google-charts";
import { Link, useLocation, useParams } from 'react-router-dom';
import { Select, Table, Spin, Row, Col, Card, PageHeader, Button, Empty, Breadcrumb } from 'antd';
import label1 from "../../../assets/label1.png";
import label2 from "../../../assets/label2.png";
import label3 from "../../../assets/label3.png";
import label4 from "../../../assets/label4.png";
import label5 from "../../../assets/label5.png";
import _, { filter } from 'lodash';
import {
    FilterOutlined,
    CalendarOutlined,
    ArrowLeftOutlined,
    ArrowRightOutlined,
} from "@ant-design/icons";
import './style.scss'
import Cookies from 'js-cookie';
import NotFound from '../../../shared/not-found';
import ReactGA from "react-ga4";

const { Option } = Select;
// const period = ['H1 2022','H2 2021']
var order = ["Price Promo", "Display", "Adjacency (No Activity)", "Packaging", "Tags/Ads"];



const DriverReport = () => {
    let userDetail = Cookies.get('user-info');
    userDetail = JSON.parse(userDetail)
    const checkCSA = userDetail.user_permissions.platform_role_id;
    const [reportYear, setReportYear] = useState(window.location.pathname.split('/')[1]);
    const [categories, setCategories] = useState([]);
    const [year, setYear] = useState()
    const [selectedCategory, setSelectedCategory] = useState();
    const [driversData, setDriversData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [timeGraphData, setTimeGraphData] = useState();
    const [loader, setLoader] = useState(true);
    const [categoryGraphData, setCategoryGraphData] = useState();
    const [ytdGraphData, setYtdGraphData] = useState();
    const [yearsOptions, setYearsOptions] = useState([]);
    const [showNorecords, setShowNorecords] = useState(false);//setMaxValue
    const [graphHeightMaxValue, setGraphHeightMaxValue] = useState(false);
    const options = {
        legend: { position: 'top', alignment: 'end', },
        chartArea: { right: 40, top: 70, left: 40, bottom: 50, width: "100%" },
        height: "480",
        bar: { groupWidth: '90%' },
        // animation: {
        //     duration: 200,
        //     easing: 'out',
        //     startup: true
        // },
        colors: ['#91E3FF'],
        annotations: {
            textStyle: {
                // fontName: 'Times-Roman',
                fontSize: 14,
                bold: true,
                // italic: true,
                opacity: 0.8,
                color: '#808080'
            }
        },
        vAxis: { title: '( Values are in % )', titleTextStyle: { bold: true }, viewWindow: { min: 0, max: graphHeightMaxValue }, gridlines: { count: 10 } }
    };

    const cOptions = {
        chartArea: { left: 40, top: 70, bottom: 50, width: "100%" },
        legend: { position: 'top', alignment: 'end' },
        height: "480",
        bar: { groupWidth: '90%' },
        // animation: {
        //     duration: 600,
        //     easing: 'out',
        //     startup: true
        // },
        colors: ['grey'],
        vAxis: { title: '( Values are in % )', titleTextStyle: { bold: true }, viewWindow: { min: 0, max: graphHeightMaxValue }, gridlines: { count: 10 } },
        annotations: {
            textStyle: {
                // fontName: 'Times-Roman',
                fontSize: 14,
                bold: true,
                // italic: true,
                color: '#808080',
                // The color of the text outline.
                // auraColor: '#d799ae',
                // The transparency of the text.
                opacity: 0.8
            }
        },
    };

    useEffect(() => {
        document.title = "Category Reports IT"
    }, [])

    const loadYears = async () => {
        const receivedYear = await getYears(reportYear).then(res => {
            return res;
        });
        // const years = receivedYear.map(item => {
        //     return [`H1 ${item}`, `H2 ${item}`]
        // }).flat();
        const years = receivedYear.half_years;
        setYearsOptions(years)
        setYear(years[0])
    }



    const onInit = async () => {
        // loadYears();
        const receivedCategories = await getCategories(reportYear)
            .then(res => {
                return res.result
            });
        setCategories(receivedCategories);
        setSelectedCategory(receivedCategories[0]);
        selectedCategory && selectedCategory[0] && handleCategoryChange()
    }

    const handleCategoryChange = async () => {
        if (selectedCategory) {
            let getFirstYear;
            setLoading(true);
            const receivedDriversData = await getReportCategoriesByDrivers(reportYear, selectedCategory).then(res => {
                if (res.sorting_order) {
                    setYearsOptions(res.sorting_order)
                    getFirstYear = res.sorting_order[0];
                    setYear(getFirstYear)
                }
                return res.result
            });
            // if(receivedDriversData && receivedDriversData.length > 0){

            // }

            setDriversData(receivedDriversData);
            graphDataByTime(receivedDriversData, getFirstYear);
            graphDataByAllCategory(receivedDriversData);
            setLoading(false)
            setGraphHeight(receivedDriversData, getFirstYear);
        }
    }
    const setGraphHeight = (receivedDriversData, selectedYear) => {

        // var graphValues = [];
        // receivedDriversData && receivedDriversData.map(user => {
        //     graphValues.push(user['Historical Category Average']);
        //     graphValues.push(user[selectedYear])

        // })  
        const graphValues = receivedDriversData && receivedDriversData.flatMap(val => [val['Historical Category Average'], val[selectedYear]])
        var filteredGraphValues = [];
        graphValues && graphValues.map((val, ind) => {
            if (val.indexOf('<1%') == -1 && val.indexOf('-') == -1 && val.indexOf('%') >= 0) {
                filteredGraphValues.push(+val.split('%').join(''));
            }
        })

        var maxValue = filteredGraphValues.length > 0 ? Math.max(...filteredGraphValues) : 0;
        var arrOfTotalPercentage = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
        arrOfTotalPercentage.map((val, ind) => {
            if (maxValue > arrOfTotalPercentage[ind] && maxValue < arrOfTotalPercentage[ind + 1]) {
                setGraphHeightMaxValue(arrOfTotalPercentage[ind + 1])
            }
        })

    }
    const getPercentValue = (value) => {

        // console.log("timedata", timeGraphData, "catdata", categoryGraphData)
        // if (typeof (value) == 'undefined') { setShowNorecords(true); }

        // else {
        // setShowNorecords(false)
        if (!value) {
            return 0
        }
        if (value === '-') {

            return 0;
        }
        else if (value.indexOf('<1%') >= 0) {
            return 0;
        }
        else if (value.indexOf('%') >= 0) {
            return +value.split('%').join('');
        }
        else {

            return value;
        }
        // }


    }
    const getPercentAnnotationValue = (value) => {

        if (!value) {
            return ''
        }
        if (value === '-') {

            return "<1";
        }
        else if (value.indexOf('<1%') >= 0) {

            return value.split('%').join('');
        }
        else if (value.indexOf('%') >= 0) {

            return +value.split('%').join('');
        }
        else {

            return value;
        }



    }






    const graphDataByTime = (driversData, year, selectPeriod = false) => {
        let isyearPresent = driversData && driversData.map(item => Object.keys(item))[0].includes(year)
        console.log("is year presenr", isyearPresent)
        setYear(year);
        // if(!isyearPresent){
        //     setShowNorecords(true);
        // }
        // else { setShowNorecords(false)}

        var filterdGraph = [];
        let SortedData = _.sortBy(driversData, function (obj) {
            return _.indexOf(order, obj.Trigger);
        });

        SortedData && SortedData.map((item, index) => {
            let graphVal = getPercentValue(item[year]);
            let annotationVal = `${getPercentAnnotationValue(item[year])} %`;
            filterdGraph.push([item.Trigger, graphVal, 'color: #91E3FF', annotationVal]);

        });
        // for (let index = 0; index < driversData.length; index++) {
        //     filterdGraph.push([driversData[index].Trigger, getPercentValue(driversData[index][year]), 'color: #91E3FF']);
        // }
        // console.log("sorted data time", filterdGraph)


        filterdGraph.unshift(['Trigger', year, { role: 'style' }, { role: 'annotation' }])
        filterdGraph && setTimeGraphData(filterdGraph)
        let undefinedPresent = filterdGraph && filterdGraph.flat().includes(undefined || '');
        console.log("under", undefinedPresent)
        if (undefinedPresent) {
            setShowNorecords(true);
        }
        else {
            setShowNorecords(false);
        }
        setGraphHeight(driversData, year);
        console.log(selectPeriod)
        /*setting activity on google analytics*/

        const category = "Categories_Report_Top_Drivers_Category_Period_Filter";
        const action = `CR_TDR/${year}${selectedCategory ? '/' : ''}${selectedCategory ? selectedCategory : ''}`;
        const label = "Test_Categories_Report_Top_Drivers_Category_Period_Filter"
        if (year && selectPeriod) {
            ReactGA.event({
                category, action, label
            })
        }
    }
    const changeGraphDataByAllCategory = (selected_category) => {
        setSelectedCategory(selected_category)
        /*setting activity on google analytics*/
        const category = "Categories_Report_Top_Drivers_Select_Category";
        const action = `CR_TDR/${year ? year : ''}${year ? '/' : ''}${selected_category}`;
        const label = "Test_Categories_Report_Top_Drivers_Select_Category"
        if (selected_category) {
            ReactGA.event({
                category, action, label
            })
        }

    }
    const graphDataByAllCategory = (driversData) => {
        let filterdGraph = [];
        let SortedData = _.sortBy(driversData, function (obj) {
            return _.indexOf(order, obj.Trigger);
        });

        SortedData && SortedData.map(item => {
            let graphVal = getPercentValue(item['Historical Category Average']);
            let annotationVal = `${getPercentAnnotationValue(item['Historical Category Average'])} %`;
            filterdGraph.push([item.Trigger, graphVal, 'color: grey', annotationVal]);
        });
        console.log("driver data cat", filterdGraph)
        filterdGraph.unshift(['Trigger', 'Historical Category Average', { role: 'style' }, { role: 'annotation' }])
        setCategoryGraphData(filterdGraph)

    }

    useEffect(() => {
        onInit()
    }, [])

    useEffect(() => {
        handleCategoryChange();
    }, [selectedCategory])
    console.log("GraphHeightMaxValue" + graphHeightMaxValue)
    return (
        <>
            {console.log("drivers update ", driversData, timeGraphData, categoryGraphData)}
            {checkCSA !== 2 ? <>
                <div className="site-page-header-ghost-wrapper" >

                    <div class="page-title">
                        <h1>Top Drivers</h1>
                        <Breadcrumb>
                            <Breadcrumb.Item><Link class="back-arrow" to={{ pathname: `/${reportYear}/category-reports` }}><Button type='primary' shape="round">Back</Button></Link></Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>

                {loading ? <Spin size='large' style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', minHeight: '450px', alignItems: 'center', }} /> :
                    <>
                        <div class="flex gx-mt-3 gx-flex-row-reverse gx-d-flex">

                            <Select
                                showSearch
                                allowClear
                                placeholder="Category"
                                optionFilterProp="children"
                                suffixIcon={<FilterOutlined />}
                                onChange={(categoryValue) => changeGraphDataByAllCategory(categoryValue)}
                                // defaultValue={selectedCategory}
                                value={selectedCategory}
                                style={{ width: 200, }}
                            >
                                {categories && categories.map((item, i) => <Option value={item} key={i}> {item}</Option>)}
                            </Select>
                            <Select
                                showSearch
                                allowClear
                                placeholder="Period"
                                optionFilterProp="children"
                                suffixIcon={<FilterOutlined />}
                                onChange={(periodValue) => { graphDataByTime(driversData, periodValue, true) }}
                                // defaultValue={year}
                                value={year}
                                style={{ width: 200, marginRight: 15 }}
                            >
                                {yearsOptions && yearsOptions.map((item, i) => <Option value={item} key={i}> {item}</Option>)}
                            </Select>
                        </div>
                        <div>
                            {driversData && !showNorecords ? <Row>
                                <Col span={6} className="driver-left-box">
                                    <div className="site-card-border-less-wrapper driverHead">
                                        <Card title="Impulse Drivers Defined" bordered={false} >
                                            <div class="icon-box">
                                                <div class="icon-img">
                                                    <img src={label1} />
                                                </div>
                                                <div class="box-dis">
                                                    <span> <b>Price Promotions</b> : Presense of price promotions, Including TPR/Sale, BOGO/Multibuy, gift card offers, etc.</span>
                                                </div>
                                            </div>
                                            <div class="icon-box">
                                                <div class="icon-img">
                                                    <img src={label2} />
                                                </div>
                                                <div class="box-dis">
                                                    <span><b>Displays</b>: Presense of displays</span>
                                                </div>
                                            </div>
                                            <div class="icon-box">
                                                <div class="icon-img">
                                                    <img src={label3} />
                                                </div>
                                                <div class="box-dis">
                                                    <span> <b>Adjacency (No Activity)</b>: Shopper is shopping for a category nearby but no sale tags, displays, or other trigger are present.</span>
                                                </div>
                                            </div>
                                            <div class="icon-box">
                                                <div class="icon-img">
                                                    <img src={label4} />
                                                </div>
                                                <div class="box-dis">
                                                    <span> <b>Tags/Ads</b>: Presense of other tags, including New, Non-GMO, WIC, etc, or ads , like shelf talkers, aisle blades, etc.</span>
                                                </div>
                                            </div>
                                            <div class="icon-box">
                                                <div class="icon-img">
                                                    <img src={label5} />
                                                </div>
                                                <div class="box-dis">
                                                    <span><b>Packaging</b>: Eye-catching Packaging that grabs shoppers attention</span>
                                                </div>
                                            </div>


                                        </Card>
                                    </div>
                                </Col>
                                <Col span={9}>
                                    {timeGraphData.length > 0 && driversData && <Chart chartType="ColumnChart" width="100%" height="400px" options={options} data={timeGraphData} />}
                                </Col>
                                <Col span={9} offset={3}>
                                    {categoryGraphData.length > 0 && driversData && <Chart chartType="ColumnChart" width="100%" height="400px" options={cOptions} data={categoryGraphData} />}
                                </Col>
                            </Row>
                                : (typeof (driversData) == 'undefined' || showNorecords) && <div class="no-record-found"><img src="../../images/no-data.png" /><h4 class="nodata-dis">You may not have subscribed to this section of the report. Please try changing filters such as time periods.<br></br> Feel free to reach your NAILBITER Client Service Contact if you need any support </h4></div>}

                        </div>
                        {/* {typeof(driversData) == 'undefined' ? <div class="no-record-found"><img src="../../images/no-data.png" /><h4 class="nodata-dis">Your subscription does not include category reports.<br></br> Please reach out to your NAILBITER contact for more information.</h4></div> :<></>} */}

                        {driversData && driversData?.length == 0 && <Empty description="No Categories Found" />}

                    </>}</> : <NotFound />}

        </>
    );
}

export default DriverReport;