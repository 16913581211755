import React from 'react';

const TermsAndConditions = () => {
    return (
        <div>
            <div class="maincontainer">
    <div class="tableBorder ">
        <br></br>
        <br></br>
        <span style={{textAlign:'center'}}>
            <h2 style={{fontSize:'25px'}}>NAILBITER Terms & Conditions</h2>
            <h4>Software as a Service (SaaS) Agreement</h4>
        </span>
        <h4>AGREEMENT</h4>
        <p>
            <span style={{fontSize:'13px'}}>Last updated: [March 3, 2022]</span><br></br>

            This Software as a Service (SaaS) Agreement (collectively, including any documents or terms
            attached
            hereto or incorporated herein, this “Agreement”), dated as of the date you click "Sign up” or “I agree” or
            otherwise indicate your assent (the “Effective Date”), is entered into by and between NAILBITER, Inc.,
            located
            at [12110 Sunset Hills Road, Suite 600, Reston, VA 20190] (“NAILBITER”), and you (“Customer” or “you”).
            NAILBITER and Customer are each referred to in this Agreement as a “party” and collectively as the
            “parties”. By
            clicking "Sign up" or "I agree" or otherwise indicating your assent, by accessing or using any Services (as
            defined in Section 2), or by entering into a Statement of Work, you agree to be bound by this Agreement.
            If
            you are entering into this Agreement on behalf of an entity, you represent that you have the authority to
            and
            you hereby bind such entity to this Agreement. If you do not have such authority or you do not agree to this
            Agreement, do not access or use any Services. In the event of any conflict between the terms of the body of
            this
            Agreement and the terms of any Statement of Work, the terms of the body of this Agreement shall control
            (unless, solely for the purposes of such Statement of Work, such Statement of Work states otherwise
            with
            specific reference to the terms to be superseded).
        </p>
        <h4> SERVICES; LIMITED LICENSE.</h4>
        <p> Subject to the terms and conditions of this Agreement and NAILBITER’s standard
            user
            policies (as made available and updated by NAILBITER from time to time), NAILBITER hereby grants to Customer
            a
            limited, worldwide, non-exclusive, non-assignable, non-transferable (except as expressly authorized in this
            Agreement) license to use the software service(s) set forth in the applicable Statement of Work
            (collectively, “Software” and together with any professional services set forth in the applicable Statement
            of
            Services, “Services”) for its internal business purposes (and, as applicable, any other purposes expressly
            stated in the applicable Statement of Work) during the term of such Statement of Work.
        </p>
        <h4> NO RELIANCE</h4>
        <p>
            Customer agrees that Customer’s purchases hereunder are neither contingent on the delivery of
            any
            future functionality or features nor dependent on any oral or written public comments made by NAILBITER
            regarding future functionality or features.
        </p>
        <h4> RESTRICTIONS</h4>
        <p>
            Customer shall not, directly or indirectly, or permit any user or third party to (i) reverse
            engineer, decompile, disassemble or otherwise attempt to discover the source code or underlying ideas or
            algorithms of any Services; (ii) modify, translate, or create derivative (or substantially similar) works
            based
            on any Services; (iii) rent, lease, distribute, sell, resell, assign, or otherwise transfer its rights to
            use
            any Services; (iv) use any Services for timesharing or service bureau purposes or otherwise for the benefit
            of a
            third party or for any competitive or unauthorized purpose; (v) remove any proprietary notices from any
            Services
            or any other NAILBITER materials furnished or made available hereunder; (vi) publish or disclose to third
            parties any evaluation of any Services; (vii) make any Services available to anyone other than users
            expressly
            authorized under the applicable Statement of Work; or (viii) interfere with or disrupt the integrity or
            performance of any Services, any NAILBITER systems, or any contents thereof.
        </p>
        <h4> USE OF THE SERVICES.</h4>
        <p>

            <b>NAILBITER’s Responsibilities</b> As may be further described in the applicable Statement of Work,
            NAILBITER
            shall (i) use commercially reasonable efforts to make the Software available 24 hours a day, 7 days a week,
            except for any suspension, any unavailability caused by any events or circumstances beyond its reasonable
            control (as further described in Section 15), and except for any scheduled or emergency maintenance; and
            (ii)
            use commercially reasonable efforts to respond to Customer’s telephone, website, and email inquiries
            (including
            requests for technical and support assistance) regarding Customer’s use of the Services.
            <br></br>
            <b>Customer’s Responsibilities</b> Customer shall (i) be responsible for each of its user’s compliance
            with
            this
            Agreement, and Customer shall ensure that each of its users agrees to and complies with NAILBITER’s
            then-current
            End-User Terms of Service; (ii) be solely responsible for the accuracy, quality, integrity and legality of
            Customer Content (as defined in Section 13.1) and of the means by which Customer Content was acquired and
            made
            available; (iii) use commercially reasonable and diligent efforts to prevent unauthorized access to or use
            of
            the Services, and notify NAILBITER promptly of any such unauthorized access or use; (iv) provide any
            required or
            reasonably requested cooperation, information, approvals, access, or assistance in connection with the
            provision
            or use of the Services (including, without limitation, Customer’s responsibilities set forth in the
            applicable
            Statement of Work); and (v) use the Services in accordance with all applicable laws and regulations.
        </p>
        <p>
            <b> Third Party Materials</b>
            From time to time, NAILBITER may make available (through the Services or otherwise)
            certain third-party services, data, content, products, applications, tools and other materials
            (collectively,
            "Third Party Materials"). Any acquisition by Customer of any Third Party Materials, and any exchange of data
            between Customer and any third party or Third Party Materials, is solely between Customer and the applicable
            non-NAILBITER provider. NAILBITER shall not be liable or responsible for any third party or any Third Party
            Materials, or for any loss, damage, injury, malfunction, interruption or claim arising therefrom or related
            thereto. NAILBITER does not warrant or support Third Party Materials or their interoperation with the
            Services,
            whether or not they are designated by NAILBITER as “certified,” “approved,” or any similar status. NAILBITER
            is
            not responsible for any disclosure, modification, or deletion of data resulting from permitted access by any
            non-NAILBITER provider. Customer shall use any such Third Party Materials solely in accordance with the
            independent rights Customer has directly secured. If any terms associated with any Third Party Materials are
            required by the applicable non-NAILBITER provider and are posted in the Software (or otherwise made
            available to
            Customer), Customer shall carefully review and agree to any such terms before accessing or using such Third
            Party Materials. Customer hereby grants to NAILBITER permission to allow the non-NAILBITER provider to
            access
            Customer-related data and information as relevant for the interoperation of Third Party Materials.
        </p>
        <h4>OWNERSHIP DATA. </h4>
        <p>
            Customer acknowledges that, as between NAILBITER and Customer, all right, title and interest in and to the
            Services and any other NAILBITER materials furnished or made available under this Agreement, and all
            modifications, versions, and enhancements thereof, including, without limitation, all rights under copyright
            and
            patent and other intellectual property rights, belong to and are retained solely by NAILBITER (or
            NAILBITER’s
            third-party licensors and suppliers, as applicable). NAILBITER reserves all rights not expressly granted
            under
            this Agreement.
        </p>
        <p>
            “Customer Data” means data that is provided by Customer, and is processed, via the Services. Customer
            represents, warrants, and covenants to NAILBITER that (i) the Customer Data shall at all times be current,
            accurate, true, lawful, lawfully obtained and complete, shall not infringe or violate any third party
            rights,
            and shall, as applicable, be updated by Customer as soon as practicable; and (ii) Customer has all rights,
            consents and authority necessary to (a) provide to NAILBITER, and for NAILBITER to use as permitted under
            this
            Agreement, the Customer Data and to otherwise facilitate NAILBITER’s performance of the Services, and (b)
            obtain
            or access any data, materials or other content that Customer requests or orders through or in connection
            with
            any Services. Customer understands that NAILBITER requires access to certain data in order to provide the
            Services. Customer hereby grants to NAILBITER the non-exclusive right to use (and for NAILBITER’s
            subcontractors
            to use on its behalf) Customer Data, in accordance with NAILBITER’s Privacy Policy and this Agreement, to
            offer,
            provide, or improve the Services or to provide Customer with information (including with respect to third
            parties or Third Party Materials) that might be relevant in connection with the Services.
        </p>
        <p>
            Customer acknowledges and agrees that NAILBITER shall have the right to utilize data capture, syndication,
            and
            analysis tools, and other similar tools, to extract, compile, synthesize, and analyze any non-personally and
            non-Customer identifiable data or information resulting from Customer’s use of the Services (“Statistical
            Data”). Statistical Data may be collected by NAILBITER for any lawful business purpose without a duty of
            accounting to Customer, provided that the Statistical Data is used only in an aggregated form, without
            specifically identifying the source of the Statistical Data. <br/>
            Except for the limited rights granted in this Agreement, at no time shall NAILBITER acquire any ownership,
            license, rights or other interest in or to the Customer Data, all of which shall, as between Customer and
            NAILBITER, be and remain the confidential and proprietary information of Customer. As between the parties,
            each
            party shall own the data it collects from third parties. <br/>
            Customer hereby grants NAILBITER a royalty-free, worldwide, transferable, sublicenseable, irrevocable,
            perpetual
            license to use or incorporate into the Services (or any other NAILBITER products, applications or services)
            any
            suggestions, enhancement requests, recommendations or other feedback provided by Customer (or any of its
            users)
            relating to the Services. Notwithstanding anything to the contrary in this Agreement, NAILBITER (and its
            affiliates) may use, for any purpose, any information of a general nature that is retained in the unaided
            memories of NAILBITER’s (or any of its affiliates’) employees or contractors.

        </p>
        <h4>WARRANTIES</h4>
        <p>

            Warranties by NAILBITER. NAILBITER represents and warrants to Customer that (i) it has the full corporate
            rights, power and authority to enter into this Agreement and to perform the acts required of it hereunder;
            (ii)
            it will not violate any applicable law or regulation in the performance of its obligations under this
            Agreement;
            and (iii) subject to Section 8.4, the Services will be provided in substantial accordance with their
            published
            specifications. The Services will be deemed accepted by Customer unless Customer provides NAILBITER with
            detailed written notice, demonstrating that such Services do not substantially comply with their published
            specifications (through no fault of Customer), within seven (7) days after such Services are first provided
            or
            made available to Customer (or such other acceptance period expressly stated in the applicable Statement of
            Services).

        </p>
        <p>
            <b>Warranties by Customer.</b> Customer represents and warrants to NAILBITER that (i) it has the full
            corporate
            rights, power and authority to enter into this Agreement and to perform the acts required of it hereunder;
            (ii)
            it will not violate any applicable law or regulation in connection with its use of the Services or its
            performance under this Agreement; (iii) it has all rights, consents and approvals necessary to provide the
            Customer Content (as defined in Section 13.1) to NAILBITER (and for NAILBITER to use the Customer Content as
            permitted or contemplated under this Agreement), and the Customer Content does not and will not violate any
            law,
            regulation or third party rights.
        </p>
        <h4>
            FEES AND TAXES
        </h4>
        <p>
            <b>Fees</b>. Customer shall pay NAILBITER the amounts described in any Statement of Work in accordance
            with
            the
            terms of this Agreement (collectively, “Fees”). Billing will occur upfrontly (unless otherwise stated in the
            applicable Statement of Work). All Fees are quoted in United States currency. Except as otherwise
            provided
            in this Agreement, all Fees are non-refundable.
        </p>
        <p>
            <b>Payments</b>. Unless otherwise stated in the applicable Statement of Work, all Fees are due and
            payable
            by
            Customer within thirty (30) days following the receipt of the invoice by Customer. The outstanding balance
            of
            any payment not received from Customer by the due date shall accrue interest (except with respect to charges
            then under reasonable and good faith dispute) at one and a half percent (1.5%) per month (or, if lower, the
            maximum rate permitted by applicable law) from the date such payment is due until the date paid. Customer
            shall
            also pay all costs incurred (including, without limitation, reasonable legal fees) in collecting overdue
            payments.

        </p>
        <p>
            <b>Taxes</b>. All Fees are exclusive of all taxes and similar fees. Customer shall be responsible for and
            shall
            pay in full all applicable sales, use, excise or similar governmental taxes imposed by any federal, state,
            or
            local governmental entity in connection with any Fees, exclusive, however, of taxes based on NAILBITER’s
            income,
            which taxes shall be paid by NAILBITER. If any taxes for which Customer is responsible hereunder are paid by
            NAILBITER, Company shall promptly reimburse NAILBITER upon Company’s receipt of proof of payment
        </p>
        <p>
            <b>Free Trial</b>. Customer shall obtain a free trial or free Services of seven (7 days) which may extend at
            NAILBITER’s discretion. NAILBITER will make such Services available to Customer, free of charge (unless
            otherwise mutually agreed by the parties in writing), until the earlier of (a) the end of the free trial
            period or (b) termination by NAILBITER in its sole discretion. Any such trial or Services shall be on an “AS
            IS” AND WITH ALL FAULTS basis; Customer assumes all risks associated with, and NAILBITER shall not have any
            liability related to, any such trial or Services. Additional trial terms and conditions may appear on
            NAILBITER’s website or the Software, and any such additional terms and conditions are incorporated into this
            Agreement by reference
        </p>
        <p>
            <b>TERM</b>. This Agreement begins on the Effective Date and shall continue, unless terminated earlier in
            accordance with this Agreement, until the termination of all Statements of Services.
        </p>
        <h4>TERMINATION</h4>
        <p>

            <b>Termination for Cause.</b> Either party may terminate this Agreement (or any Statement of Work) upon
            written
            notice if the other party has breached a material term of this Agreement and has not cured such breach
            within
            thirty (30) days of receipt of notice from the non-breaching party specifying the breach.
        </p>
        <p>
            <b>Insolvency</b> Either party shall have the right to terminate this Agreement (or any Statement of
            Services)
            if (i)
            the other party has a receiver appointed for it or its property; (ii) the other party makes a general
            assignment
            for the benefit of creditors; (iii) any proceedings are commenced by, for or against the other party under
            any
            bankruptcy, insolvency or debtor's relief law (which proceedings, if involuntary, are not dismissed within
            thirty (30) days); or (iv) the other party is liquidated or dissolved.

        </p>
        <p>
            <b>Failure to Pay/Customer Conduct</b> NAILBITER shall have the right to suspend or terminate access to the
            Services, at its sole option, with or without notice to Customer if (i) any undisputed payment is delinquent
            by
            more than thirty (30) days; or (ii) NAILBITER reasonably determines that continued access would, or is
            reasonably likely to, result in a violation of security, applicable law, or any intellectual property,
            privacy
            or other rights
        </p>
        <p>
            <b>Immediate Termination</b> Either party may immediately terminate this Agreement (or any Statement of
            Services) for any breach of Sections 4 or 11 by the other party.

        </p>
        <p>
            <b>Effect of Termination</b> NAILBITER shall not be liable to Customer or any third party for suspension or
            termination of Customer’s access to, or right to use, the Services. Upon termination of this Agreement,
            Customer
            shall pay (if not already prepaid) the balance due for the Services through the remainder of the
            then-current
            term under the applicable Statement of Work (unless otherwise expressly stated in the applicable
            Statement
            of Services). Upon the effective date of termination of this Agreement, Customers’ access to and use of the
            Services (and all then-effective Statements of Services) shall automatically and immediately terminate.
            Sections
            4, 5.3, 6, 8, 10.5 and 11-15 of this Agreement shall survive the termination of this Agreement.
        </p>
        <h4>CONFIDENTIALITY</h4>
        <p>
            <b>Obligations</b> Each of the parties agrees to maintain in confidence any proprietary or non-public
            information of the other party, whether written or otherwise, disclosed by the other party in the course of
            performance of this Agreement that a party knows or reasonably should know is considered confidential by the
            disclosing party (“Confidential Information”). NAILBITER’s Confidential Information includes, without
            limitation, any non-public information constituting, associated with, embedded in, or copied, derived,
            received,
            downloaded, or otherwise obtained from any Services (and any portions, copies, summaries, embodiments, or
            derivatives of any of the foregoing, in any form or media). The parties hereby agree that the terms and
            conditions of this Agreement, and any discussions between the parties related to the Services, shall be
            considered Confidential Information. The receiving party shall not disclose to any third party or use any of
            the
            disclosing party’s Confidential Information, except as reasonably necessary to perform its obligations under
            this Agreement, and shall take such actions as are reasonably necessary and appropriate to preserve and
            protect
            such Confidential Information and the disclosing party’s rights therein, at all times exercising at least a
            reasonable level of care. Each party agrees to restrict access to the Confidential Information of the other
            party to those employees or agents who reasonably require access in order to perform hereunder and who are
            bound
            by confidentiality and restricted use obligations at least as protective as those set forth herein. Upon
            termination of this Agreement, and at the request of the disclosing party, the receiving party shall
            promptly
            return or destroy (at the disclosing party’s option), all copies of the disclosing party’s Confidential
            Information. Upon Customer’s reasonable request, NAILBITER will in good faith consider granting Customer
            permission to publish or present certain materials that Customer downloads from (or otherwise obtains or
            derives
            in connection with Customer’s authorized use of) the Services.
        </p>
        <p>
            <b>Exclusions</b> Confidential Information shall not include any information that is (i) already known to
            the
            receiving party at the time of the disclosure; (ii) publicly known at the time of the disclosure or becomes
            publicly known through no wrongful act or failure of the receiving party; (iii) disclosed to the receiving
            party
            on a non-confidential basis by a third party that, to the receiving party’s knowledge, has obtained such
            information lawfully and has the right to make such disclosure; or (iv) communicated to a third party by the
            receiving party with the express written consent of the disclosing party. A disclosure of Confidential
            Information that is legally compelled to be disclosed pursuant to a subpoena, summons, order or other
            judicial
            or governmental process shall not be considered a breach of this Agreement; provided the receiving party
            provides prompt notice of any such subpoena, order, or the like to the disclosing party so that such party
            will
            have the opportunity to obtain a protective order or otherwise oppose the disclosure.

        </p>
        <p>
            <b>WARRANTY DISCLAIMER.</b> EXCEPT AS EXPRESSLY PROVIDED IN THIS AGREEMENT, THE SERVICES ARE PROVIDED “AS
            IS,”
            AND, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, NAILBITER MAKES NO (AND HEREBY DISCLAIMS ALL)
            OTHER
            WARRANTIES, REPRESENTATIONS, IMPLIED WARRANTIES OR MERCHANTABILITY, TITLE, AGAINST INFRINGEMENT, OR FITNESS
            FOR
            A PARTICULAR PURPOSE, WITH RESPECT TO THE USE, MISUSE, OR INABILITY TO USE THE SERVICES (IN WHOLE OR IN
            PART),
            ANY THIRD PARTY MATERIALS, OR ANY OTHER PRODUCTS OR SERVICES PROVIDED TO CUSTOMER BY NAILBITER, OR OTHERWISE
            UNDER THIS AGREEMENT. WITHOUT LIMITING THE FOREGOING, NAILBITER DOES NOT WARRANT THAT ALL ERRORS CAN BE
            CORRECTED, OR THAT USE OF THE SERVICES SHALL BE UNINTERRUPTED OR ERROR FREE. NAILBITER is not responsible
            for,
            and does not endorse, any communications or content submitted by, or shared among, any users of the
            Services.
        </p>
        <h4>INDEMNIFICATION.</h4>
        <p>
            By Customer. Customer shall indemnify, defend, and hold harmless NAILBITER, its affiliates, directors,
            officers,
            employees, and agents against any third-party claim or suit arising from any data, materials, access,
            credentials, content or instructions provided or made available by or on behalf of Customer (collectively,
            including all Customer Data, “Customer Content”) or from any breach by Customer (or any of its users) of
            this
            Agreement, and Customer shall pay any final judgment entered against NAILBITER in any such proceeding or
            agreed
            to in settlement. NAILBITER will promptly notify Customer in writing of such claim or suit and give all
            information and assistance reasonably requested by Customer or such designee.
        </p>
        <p>
            <b>By NAILBITER.</b> Subject to Section 8.4, NAILBITER shall indemnify, defend, and hold harmless Customer
            against
            any third-party claim or suit to the extent based on a claim that the Software (excluding any Third Party
            Materials)
            violates, infringes or misappropriates any United States patent, copyright, trademark or trade secret and
            NAILBITER
            shall pay any final judgment entered against Customer in any such proceeding or agreed to in settlement
            provided:
            (i) NAILBITER is promptly notified in writing of such claim or suit; (ii) NAILBITER or its designee has sole
            control
            of such defense or settlement; (iii) Customer gives all information and assistance requested by NAILBITER or
            such
            designee; and (iv) such claim does not result from any Customer Content or any unauthorized access, use,
            modification or combination of any Services. To the extent that use of the Software is enjoined, NAILBITER
            may
            at
            its option either (a) procure for Customer the right to use the Software; (b) replace the Software with a
            similar
            service; or (c) refund the prepaid, unused portion (as applicable) of the Fee(s) paid by Customer for the
            Software
            or the affected part thereof. NAILBITER shall have no liability under this Section 13.2 or otherwise to the
            extent a
            claim or suit results from any negligence or willful misconduct by or on behalf of Customer or is covered by
            Customer’s indemnification obligations under Section 13.1.
        </p>
        <p>
            THIS SECTION 13.2 STATES NAILBITER’S ENTIRE LIABILITY AND CUSTOMER'S SOLE AND EXCLUSIVE REMEDY FOR ANY
            INFRINGEMENT CLAIMS RELATED TO THE SERVICES.

        </p>
        <h4>
            LIMITATIONS OF LIABILITY
        </h4>
        <p>
            <b>Limitation on Direct Damages.</b> IN NO EVENT SHALL NAILBITER’S AGGREGATE LIABILITY, IF ANY, ARISING OUT
            OF
            OR IN
            ANY WAY RELATED TO THIS AGREEMENT EXCEED THE FEES PAID BY CUSTOMER UNDER THE APPLICABLE STATEMENT OF
            SERVICES
            DURING THE SIX (6) MONTH PERIOD PRECEDING THE EVENT THAT FIRST GAVE RISE TO THE DAMAGES CLAIMED, REGARDLESS
            OF
            THE NATURE OF THE CLAIM OR WHETHER SUCH DAMAGES WERE FORESEEABLE
        </p>
        <p>
            <b>Waiver of Consequential Damages.</b> EXCEPT FOR ANY BREACH OF SECTIONS 4 OR 11, IN NO EVENT SHALL EITHER
            PARTY BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE OR CONSEQUENTIAL DAMAGES, OR FOR ANY LOSS OF
            DATA, GOODWILL, OPPORTUNITY, REVENUE OR PROFITS, REGARDLESS OF THE NATURE OF THE CLAIM OR WHETHER SUCH
            DAMAGES
            WERE FORESEEABLE.
        </p>
        <p>
            <b>GENERAL</b> The parties to this agreement are independent entities, and no agency, partnership franchise,
            joint venture or employee-employer relationship is intended or created by this Agreement. Except as
            otherwise
            set forth in this Agreement, all notices to a party shall be in writing and sent to the applicable address
            specified in Section 1 or associated with Customer’s account (which address may be updated by such party
            from
            time to time by written notice), and shall be deemed to have been duly given when received, if personally
            delivered; when receipt is electronically confirmed, if transmitted by facsimile or email; the day after it
            is
            sent, if sent for next day delivery by recognized overnight delivery service; and upon receipt, if sent by
            certified or registered mail, return receipt requested. Any notices to Customer may be sent by email to the
            email address associated with Customer’s account or may be posted in the Software, and any such notices
            shall be
            effective when delivered in accordance with the foregoing. Customer hereby consents, on behalf of itself and
            its
            users, to receiving notices and communications from NAILBITER electronically. This Agreement may not be
            assigned
            or transferred by Customer without NAILBITER’s prior written consent. Any assignment in derogation of the
            foregoing is null and void. NAILBITER may (i) subcontract any of its obligations or responsibilities under
            this
            Agreement, or (ii) assign or transfer this Agreement to an affiliate or in connection with a merger,
            acquisition, reorganization, sale of all or substantially all of its equity or assets to which this
            Agreement
            relates, or other similar corporate transaction. This Agreement shall inure to the benefit of each party’s
            successors and permitted assigns. This Agreement, including all Exhibits and Statements of Services,
            constitutes
            the entire agreement between the parties and supersedes all prior or contemporaneous agreements and
            understandings regarding the subject matter hereof. From time to time, in NAILBITER’s sole discretion,
            NAILBITER
            may amend the terms and conditions of this Agreement (other than the specific terms of, and expressly set
            forth
            in, a Statement of Work). Such changes (a) will become effective upon the date specified in NAILBITER’s
            notice of such changes, provided that NAILBITER will (unless otherwise required to comply with applicable
            law)
            provide Customer with at least thirty (30) days’ prior notice of any material changes to the terms of this
            Agreement, and (b) for clarity, will not apply retroactively. By continuing to receive, use or access any
            Services, Customer agrees to be bound by the amended terms of this Agreement. No other change of any of the
            provisions hereof shall be effective unless and until set forth in writing and duly signed by an officer of
            NAILBITER and by Customer. This Agreement shall be governed by the laws of the State of Virginia, excluding
            its
            conflict of laws rules. Each party hereby irrevocably submits to the exclusive jurisdiction of the state and
            federal courts located in [Virginia], provided that either party may seek preliminary equitable relief in
            any
            court of competent jurisdiction in connection with any actual or threatened breach of Sections 4 or 11. The
            failure to insist upon strict compliance with any of the provisions of this Agreement shall not be deemed a
            waiver of any such provision, nor shall any waiver or relinquishment of any right or power hereunder, at any
            one
            or more times, be deemed a waiver or relinquishment of such right or power at any other time or times.
            NAILBITER
            may use Customer’s name and logo in marketing, subject to Customer’s standard trademark usage guidelines (if
            applicable, as provided by Customer to NAILBITER). During the term of this Agreement, either party may
            describe
            (in general, non-disparaging terms) the nature of the relationship between the parties. Any provision of
            this
            Agreement held to be unenforceable shall be enforced to the maximum extent permissible under applicable law
            and
            shall not affect the enforceability of any other provisions of this Agreement. Neither party shall be in
            breach
            or liable for its delay or failure in performing any obligation (other than payment obligations) under this
            Agreement to the extent resulting from any events or circumstances beyond such party’s reasonable control,
            including, without limitation, acts of God, civil commotion, war, strikes, epidemics, Internet service
            interruptions or slowdowns, vandalism or “hacker” attacks, acts of terrorism, or governmental actions.
        </p>
    </div>
    </div>
</div>
    );
};

export default TermsAndConditions;