/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-console */
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { baseAddress, replaceURLAddress } from "../config";
import { logout, refresh } from "../services/auth";

// eslint-disable-next-line import/no-extraneous-dependencies
const moment = require("moment");

const createParameterizedUrl = (url, params) => {
  let newUrl = url;
  Object.keys(params).forEach((param) => {
    const regex = new RegExp(`{${param}}`, "g");
    newUrl = newUrl.replace(regex, params[param]);
  });
  return newUrl.replace(/\/\//g, "/");
};

const createQueriedUrl = (url, queries) => {
  let newUrl = url;
  Object.keys(queries).forEach((query, index) => {
    newUrl +=
      index > 0 ? `&${query}=${queries[query]}` : `?${query}=${queries[query]}`;
  });
  return newUrl;
};

const newAccessToken = async () => {
  try {
    const rtoken = Cookies.get("token");
    if (rtoken) {
      const newToken = await refresh()
        .then((res) => {
          if (res.status !== 200) {
            Cookies.remove("token");
            Cookies.remove("rtoken");
            // eslint-disable-next-line no-restricted-globals
            location.reload();
            return false;
          }
          return res.json();
        })
        .then((res) => res.token)
        .catch((err) => {
          throw err;
        });
      if (newToken) {
        Cookies.set("token", newToken, { sameSite: "Lax" });
        return newToken;
      }
    }
  } catch (err) {
    return console.error(err);
  }
};

const tokenExpired = (token) => {
  const decodedToken = jwtDecode(token);
  const expTime = moment.unix(decodedToken.exp);
  return moment(expTime).isBefore(moment());
};

export const crudData = async (
  apiEndpoint,
  method,
  parameterObject,
  payload,
  queryObject
) => {
  let accessToken = Cookies.get("token");
  let modifiedApiEndpoint = apiEndpoint;
  if (parameterObject) {
    modifiedApiEndpoint = createParameterizedUrl(
      modifiedApiEndpoint,
      parameterObject
    );
  }
  if (queryObject) {
    modifiedApiEndpoint = createQueriedUrl(modifiedApiEndpoint, queryObject);
  }
  try {
    if (!accessToken || tokenExpired(accessToken)) {
      // accessToken = newAccessToken();
      // if (!accessToken) {
      // const rtoken = localStorage.getItem('rtoken');
      Cookies.remove("rtoken");
      window.location.replace(process.env.REACT_APP_API_REPLACE_ENDPOINT);
      //  window.location.replace(replaceURLAddress);
      logout();
      // window.location.replace('http://localhost:4200/auth/login')
      Cookies.remove("token");
      // }
    }
    if (accessToken) {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      if (payload instanceof FormData) {
        headers.Accept = "application/json";
      }
      // const res = await fetch(baseAddress + modifiedApiEndpoint, {
      const res = await fetch(process.env.REACT_APP_API_ENDPOINT + modifiedApiEndpoint, {
        headers,
        method,
        body: method !== "GET" ? JSON.stringify(payload) : null,
      }).catch((err) => new Error(err));
      return res;
    }
  } catch (err) {
    return console.error(err);
  }
};
// crudData.catch(err => console.log(err))

// needs auth implementation

export const multipartCrudData = (
  apiEndpoint,
  method,
  parameterObject,
  payload,
  queryObject
) => {
  let modifiedApiEndpoint = apiEndpoint;
  if (parameterObject) {
    modifiedApiEndpoint = createParameterizedUrl(
      modifiedApiEndpoint,
      parameterObject
    );
  }
  if (queryObject) {
    modifiedApiEndpoint = createQueriedUrl(modifiedApiEndpoint, queryObject);
  }
  // return fetch(baseAddress + modifiedApiEndpoint, {
  return fetch(process.env.REACT_APP_API_ENDPOINT + modifiedApiEndpoint, {
    method,
    headers: { encType: "multipart/form-data" },
    body: payload,
  })
    .then((res) => res.json())
    .catch((err) => console.error(err));
};
