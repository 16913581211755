const { crudData } = require('../../utils/apiService');




const getCategoriesHome = (reportYear, reportType) => {
  const res = crudData('/reports/top_categories_home/', 'GET', {}, {}, { report_name: reportYear, report_type: reportType });
  return res;
};

const getCategoriesByTime = (reportYear) => {
  const res = crudData('/reports/filters/', 'GET', {}, {}, { filter_by: 'time', menu_section: 'top-categories', report_name: reportYear });
  return res;
};

const getCategoriesByRetailer = (reportYear) => {
  const res = crudData('/reports/filters/', 'GET', {}, {}, { filter_by: 'retailer', menu_section: 'top-categories', report_name: reportYear });
  return res;
};

const getCategoriesByActivity = (reportYear) => {
  const res = crudData('/reports/filters/', 'GET', {}, {}, { filter_by: 'activity', menu_section: 'top-categories', report_name: reportYear });
  return res;
};

const getCategoriesByLocation = (reportYear) => {
  const res = crudData('/reports/filters/', 'GET', {}, {}, { filter_by: 'location', menu_section: 'top-categories', report_name: reportYear });
  return res;
};


export {
  getCategoriesHome,
  getCategoriesByTime,
  getCategoriesByRetailer,
  getCategoriesByActivity,
  getCategoriesByLocation
};
