import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <title>NAILBITER Platform - Privacy Policy</title>
    <base href="/" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <link rel="icon" type="image/x-icon" href="favicon.ico" />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900"
      type="text/css"
    />
    <link
      rel="stylesheet"
      href="https://themesbrand.com/minia/layouts/assets/css/bootstrap.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
    />
  </head>
  <body>
    <div class="main-content">
      <div class="page-content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-10 offset-md-1">
              <div
                class="page-title-box d-sm-flex align-items-center justify-content-between"
              >
                <h4 class="mb-sm-0 font-size-18">Privacy Policy</h4>

                <div class="page-title-right">
                  <ol class="breadcrumb m-0">
                    <li class="breadcrumb-item">
                      <a href="javascript: void(0);">Pages</a>
                    </li>
                    <li class="breadcrumb-item active">Privacy Policy</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <br /><br />
          <div class="row">
            <div class="col-lg-10 offset-md-1">
              <div class="card">
                <div class="card-body">
                  <p>
                    1. OBJECTIVE <br />
                    The purpose of this policy is to maintain privacy of and
                    protect the personal informa on of consumers, third party
                    vendors, clients, Users, employees, contractors, associates
                    and business partners of NAILBITER. This Privacy Policy is
                    meant to help to understand what informa on NAILBITER
                    collects, purpose of collec on, and how to access, modify,
                    manage, restrict and delete informa on. By accessing or
                    using the Services via any means (PC, mobile or other
                    device), data subject consents to NAILBITER’s collec on and
                    use of personal informa on as specified in this Privacy
                    Policy. Personal Data or Personal Informa on shall mean any
                    informa on or set of informa on that can be used to
                    personally iden fy a living, iden fied or iden fiable
                    individual (a “data subject”). <br /><br />
                    2. SCOPE<br />
                    This policy is applicable to all NAILBITER consumers, third
                    party vendors, clients, Users ,employees, contractors,
                    associates and business partners who receive, access,
                    collect or process the personal informa on or who provides
                    informa on to NAILBITER.<br /><br />
                    3. RESPONSIBILITY<br />
                    Compliance with the data privacy policy rests with the heads
                    of the individual func ons, business units and departments
                    together with any individual employees collec ng, using or
                    otherwise processing personal Informa on and shall be
                    reviewed on an annual basis by the Chief Technology Officer
                    to monitor through the implementa on of security
                    measurements and periodic review processes.<br /><br />
                    4. DATA PRIVACY PRINCIPLES <br />These principles shall
                    govern the use, collec on, disposal and transfer of personal
                    informa on, except as specifically provided by this
                    Policy<br />
                  </p>

                  <div style={{paddingLeft:'50px'}}>
                    4.1 <b>Notice</b> - For collecting Personal Informa on
                    directly from individuals, NAILBITER strives to provide
                    clear and appropriate no ce about the:
                    <ul>
                      <li>
                        Purposes for which it collects and uses their Personal
                        Informa on
                      </li>
                      <li>
                        Types of non-Agent third party vendors to which
                        NAILBITER may disclose that informa on
                      </li>
                      <li>
                        Choices and means, if any, NAILBITER offers individuals
                        for limi ng the use and disclosure of their Personal
                        Informa on.
                      </li>
                    </ul>

                    4.2 <b>Choice</b> - NAILBITER shall give data subjects the
                    choices and obtain their consent regarding how it collects,
                    uses, and discloses their personal informa on <br /><br />
                    4.3 <b>Accountability for Onward Transfer</b> - In regard to
                    the transfer of Personal Informa on, NAILBITER strives to
                    take reasonable and appropriate steps to transfer such
                    Personal Informa on only for specified purposes in a secured
                    manner<br /><br />

                    4.4 <b>Security</b> - NAILBITER takes reasonable and
                    appropriate measures to protect Personal Informa on from
                    loss, misuse and unauthorized access, disclosure, altera on
                    and destruc on, taking into account the risks involved in
                    the Processing and the nature of the Personal Informa on.<br /><br />
                    4.5 <b>Use, Reten on and Disposal</b> - NAILBITER shall only
                    use personal informa on that has been collected for the
                    purposes iden fied in the privacy no ce / SOW / contract
                    agreements and in accordance with the consent that the data
                    subject shall provide. NAILBITER shall not retain personal
                    informa on longer than is necessary to fulfill the purposes
                    for which it was collected and to maintain reasonable
                    business records. NAILBITER shall dispose of the personal
                    informa on once it has served its intended purpose or as
                    specified by the data subject.<br /><br />
                    4.6 <b>Disclosure to Third Party vendors</b> - NAILBITER
                    shall disclose personal informa on to Third Party vendor/
                    partner firms only for purposes iden fied in the privacy no
                    ce / SOW / contract agreements. NAILBITER shall disclose
                    personal informa on in a secure manner, with assurances of
                    protec on by those par es, according to the contracts, laws
                    and other segments, and, where needed, with consent of the
                    data subject.<br /><br />
                    4.7 <b>Recourse, Enforcement and Liability</b> - Viola on of
                    this policy by any employee or contractor of NAILBITER shall
                    result in a disciplinary ac on including termina on.Viola on
                    of this policy by any other party of this policy or
                    NAILBITER’s privacy requirements shall result in exercising
                    appropriate legal remedies available at law or in equity
                    including termina on for material breach of contract.<br /><br />
                    4.8 <b>Quality</b> - NAILBITER shall take steps to ensure
                    that personal informa on in its records is accurate and
                    relevant to the purposes for which it was collected.<br /><br />
                  </div>
                  5. COLLECTION OF PERSONAL INFORMATION<br />
                  What personal data does NAILBITER collect ?
                  <ul>
                    <li>
                      Information on that data subject knowingly choose to
                      disclose;
                    </li>
                    <li>
                      Information collected while contacting us, using
                      applications or services or visiting the website or using
                      application;
                    </li>
                    <li>
                      Information NAILBITER gets from other third party vendors
                      who have obtained consent to share personal informa on may
                      be collected online or offline. Regardless of the collec
                      on method, the same privacy protec on shall apply to all
                      personal informa on. NAILBITER does not take any
                      responsibility for personal informa on that is collected
                      by a third party vendor and provided to us for processing.
                    </li>
                  </ul>
                  <div style={{paddingLeft:'50px'}}>
                    5.1 Personal informa on shall not be collected unless either
                    of the following is fulfilled:
                    <ul>
                      <li>
                        The data subject has provided a valid, informed and free
                        consent;
                      </li>
                      <li>
                        Processing is necessary for the performance of a service
                        to which the data subject is a party or in order to take
                        steps at the request of the data subject prior to
                        entering into a contract
                      </li>
                      <li>
                        Processing is necessary for compliance with the
                        NAILBITER legal obligation;
                      </li>
                      <li>
                        Processing is necessary in order to protect the vital
                        interests of the data subject;
                      </li>
                    </ul>

                    5.2 Data subjects shall not be required to provide more
                    personal informa on than is necessary for the provision of
                    the product or service that data subject has requested or
                    authorized. If any data not needed for providing a service
                    or product is requested, such fields shall be clearly
                    labeled as op onal. Collec on of personal informa on shall
                    be avoided or limited when reasonably possible.<br /><br />
                    5.3 Personal informa on shall be de-iden fied when the
                    purposes of data collec on can be achieved without
                    personally iden fiable informa on, at reasonable cost.<br /><br />
                    5.4 When using third party vendors to collect personal
                    informa on on the behalf of NAILBITER, it shall ensure that
                    the vendors comply with the privacy requirements of
                    NAILBITER as defined in this Policy.<br /><br />
                    5.5 The project team/support func on shall obtain approval
                    from the IT Security team before adop ng the new methods for
                    collec ng personal informa on electronically.<br /><br />
                    5.6 NAILBITER shall review the privacy policies and collec
                    on methods of Third-Par es before accep ng personal informa
                    on from Third-Party data sources.<br /><br />
                  </div>
                  6. PURPOSE OF COLLECTION AND USE OF PERSONAL INFORMATION<br />
                  <div style={{paddingLeft:'50px'}}>
                    6.1 NAILBITER Inc uses the Personal Data of its Data
                    subjects for the following broad purposes:
                    <ul>
                      <li>
                        The general running and business administra on of
                        NAILBITER Inc.
                      </li>
                      <li>
                        For market research purposes to guide our clients on how
                        to improve their products to be er meet consumer needs.
                      </li>
                      <li>
                        To provide services to NAILBITER Inc stakeholders and
                        clients
                      </li>
                      <li>
                        The ongoing administra on and management of NAILBITER
                        services.
                      </li>
                    </ul>

                    6.2 NAILBITER shall use that Personal Informa on to provide
                    consumers, third party vendors, clients, Users ,employees,
                    contractors, associates and business partners with informa
                    on and services and to help NAILBITER personnel be er
                    understand their needs and interests.<br /><br />
                    6.3 NAILBITER uses informa on to help complete a transac on
                    or order, to facilitate communica on, to market and sell
                    products and services, to deliver products/services, to bill
                    for purchased products/services, and to provide ongoing
                    service and support. Occasionally NAILBITER personnel may
                    use Personal Informa on to contact consumers, third party
                    vendors, clients, Users ,employees, contractors, associates
                    and business partners to complete surveys that are used for
                    market research and quality assurance purposes.<br /><br />
                  </div>
                  7. SHARING PERSONAL INFORMATION WITH OTHERS<br />
                  <div style={{paddingLeft:'50px'}}>
                    7.1 NAILBITER may share Personal Informa on as necessary to:
                    <ul>
                      <li>
                        To help us in providing market research services to our
                        clients
                      </li>
                      <li>To support the other business need</li>
                      <li>
                        To help us protect our rights or property, e.g., fraud
                        preven on or informa on security
                      </li>
                      <li>
                        Comply with laws and regula ons or respond to li ga on
                      </li>
                      <li>
                        Enforce the terms and condi ons of the Terms of Use made
                        available on the Website or applica on
                      </li>
                    </ul>
                    7.2 NAILBITER may share Personal Informa on with its
                    consumers, third party vendors, clients, Users ,employees,
                    contractors, associates and business partners to the extent
                    needed. All such par es shall keep the personal Informa on
                    confiden al that is received from NAILBITER and shall not
                    use it for any purpose other than as originally intended or
                    subsequently authorized or permi ed.<br /><br />
                  </div>
                  8. EXERCISING ACCESS, DATA PORTABILITY AND DELETION RIGHTS<br />
                  <div style={{paddingLeft:'50px'}}>
                    8.1 Data Subjects have personal data rights, including
                    <ul>
                      <li>
                        Access the personal data NAILBITER holds about data
                        subjects.
                      </li>
                      <li>
                        Request that inaccurate, outdated, or no longer
                        necessary informa on be corrected, erased, or
                        restricted.
                      </li>
                      <li>
                        Ask NAILBITER to provide data in a format that transfers
                        it to another service provider.
                      </li>
                      <li>
                        Withdraw consent at any me wherever relying on consent
                        for the processing of personal informa on.
                      </li>
                      <li>
                        Object to our processing of personal data where that
                        processing is based on our legi mate interest Request
                        correc on, dele on, or transfer of personal informa on
                      </li>
                    </ul>
                    8.2 To exercise the access, data portability, and dele on
                    rights described above, Data Subject shall submit a request
                    at security@nail-biter.com by complying with below measures:
                    <ul>
                      <li>
                        Provide sufficient informa on to reasonably verify the
                        person about whom NAILBITER has collected personal
                        informa on or an authorized representa ve.
                      </li>
                      <li>
                        Describe a reason for such a request to properly
                        understand, evaluate, and respond to it.
                      </li>
                    </ul>

                    8.3 NAILBITER shall not respond to a request or provide
                    personal informa on if NAILBITER cannot verify iden ty or
                    authority to make the request and confirm the personal
                    informa on relates to the requestor. Making a verifiable
                    request does not require crea ng an account. NAILBITER shall
                    only use personal informa on provided in a verifiable
                    request to verify the requestor's iden ty or authority to
                    make the request.<br /><br />
                  </div>
                  9. COOKIES<br />
                  NAILBITER may also store informa on using cookies that can be
                  accessed when the data subject visits or uses the Services. It
                  may include informa on about the pages viewed, the links
                  clicked, and other ac ons taken using our Services or e-mail
                  content. This Informa on shall be used to improve the
                  experience of the Services.<br /><br />
                  10. CHILDREN’S PRIVACY<br />
                  Protec ng the privacy of the very young is uniquely important,
                  par cularly on the Internet. The Services are designed to
                  provide informa on and services for adults. The Services are
                  not designed to a ract minors, in par cular children under the
                  age of 13. NAILBITER does not market to or knowingly collect
                  informa on from anyone under the age of 13. Children should
                  always receive permission from their parents before sending
                  any informa on about themselves (such as their names, email
                  addresses, and phone numbers) to anyone over the Internet<br /><br />
                  11. RESPONSE TIMING AND FORMAT OF REQUEST<br />
                  11.1 NAILBITER endeavors to respond to a verifiable request
                  within 30 days of its receipt. If it requires more me (up to
                  90 days), It shall be informed to the requester of the reason
                  and extension period in wri ng.
                  <ul>
                    <li>
                      If the requester has an account, NAILBITER shall deliver a
                      wri en response to that account.
                    </li>
                    <li>
                      If the requester does not have an account, NAILBITER shall
                      deliver a wri en response by mail or electronically, at
                      their op on.
                    </li>
                    <li></li>
                  </ul>

                  11.2 Any disclosures shall only cover the 12-month period
                  preceding the verifiable request's receipt. The response shall
                  also explain the reasons if cannot comply with a request, if
                  applicable. 11.3 NAILBITER shall not charge a fee to process
                  or respond to verifiable requests unless it is excessive,
                  repetitive, or manifestly unfounded. If it is determined that
                  the request warrants a fee, NAILBITER shall inform the
                  requester why that decision has been made and provide a cost
                  es mate before comple ng any request.<br /><br />

                  12. NOTIFICATION OF DATA BREACH<br />
                  NAILBITER shall no fy the data subject about any data breach
                  that happens along with all the details within 2 business
                  days.<br /><br />
                  13. CHANGES TO THIS PRIVACY POLICY<br />
                  NAILBITER reserves the right to make changes to this Privacy
                  Policy. If any material changes are made to this Privacy
                  Policy, NAILBITER shall no fy by pos ng the new version of the
                  policy on the Website. It is the data subject’s responsibility
                  to check the Website for such pos ngs from me to me. Con nued
                  use of the Services following the pos ng of changes to this
                  Privacy Policy shall cons tute an acceptance of such
                  changes.<br /><br />
                  14. GRIEVANCE REDRESSAL<br />
                  If the data subject is not sa sfied with our data handling,
                  processing or any other policies, the data subject has a right
                  to inform at security@nail-biter.com.

                </div>
             
              </div>
             
            </div>
            
          </div>
        </div>

        <footer class="footer">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <script>
                  document.write(new Date().getFullYear());
                </script>
                © Minia.
              </div>
              <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">
                  Design & Develop by
                  <a href="#!" class="text-decoration-underline">Themesbrand</a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </body>
</html>
        </div>
    );
};

export default PrivacyPolicy;