import {
  Layout,
  Menu,
  Row,
  Col,
  Button,
  Modal,
  Space,
  Avatar,
  Dropdown,
  Popover,
  Spin,
  PageHeader,
} from "antd";
import { Link, useLocation, useParams } from "react-router-dom";
import "./AppHeader.scss";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  PoweroffOutlined,
  ExclamationCircleOutlined,
  DownOutlined,
  CalendarOutlined,
  ShopOutlined,
  GlobalOutlined,
  TagOutlined,
  PushpinOutlined,
} from "@ant-design/icons";

import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { Select } from "antd";
import { useEffect, useState } from "react";
import uklogo from "../../../src/assets/uk-logo.png";
import uslogo from "../../../src/assets/us-logo.png";
import singapore from "../../../src/assets/singapore.png";
import { replaceURLAddress } from "../../config";
import { Navigate } from "react-router-dom";

import _ from "lodash";
import { logout } from "../../services/auth";
import { getYears } from "../../services/category-reports";
import { displayFilter } from "../../services/category-reports";
import ReactGA from "react-ga4";
const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID; // Staging GA4 Measurement ID
ReactGA.initialize(TRACKING_ID);


const { Option } = Select;
const { Header, Content, Footer } = Layout;

const AppHeader = () => {
  const [currentRoot, setCurrentRoot] = useState(
    window.location.pathname + window.location.search
  );
  useEffect(() => {
    setCurrentRoot(window.location.pathname + window.location.search);
    ReactGA.set({ userId: userDetail.id });
  });

  let location = useLocation();

  let navigate = useNavigate();
  const { report_year: ReprtYear, category } = useParams();
  console.log("repirt check", ReprtYear);
  const confirm = () => {
    setVisible(false);
    Modal.confirm({
      className: "logout-popup",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to Logout?",
      okText: "Ok",
      centered: true,
      cancelText: "Cancel",
      onOk: () => {
        logout();
        Cookies.remove("token");
        window.location.replace(process.env.REACT_APP_API_REPLACE_ENDPOINT);
        //window.location.replace(replaceURLAddress);
      },
      maskClosable: true,
    });
  };

  const [selectedYearCountry, setSelectedYearCountry] = useState();
  const [selectedLogo, setSelectedLogo] = useState();
  const [reportYear, setReportYear] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [yearOptions, setYearsOptions] = useState();
  const [loading, setLoading] = useState();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const [menuSelected, setMenuSelected] = useState(true);
  const [activeKey, SetActivekey] = useState("");
  const [years, setYears] = useState();
  const [quarterYears, setQuarterYears] = useState();
  const [keysForQuarter, setKeysForQuarter] = useState();
  const yearCountry = window.location.pathname.split("/")[1];
  const updateSelectedYearCountry = () => {
    setReportYear(yearCountry);
    setSelectedYearCountry(yearCountry);
    if (yearCountry.includes("us_2021")) {
      console.log("yearCountry us", yearCountry);
      setSelectedLogo(uslogo);
    } else if (yearCountry.includes("uk_2021")) {
      console.log("yearCountry uk", yearCountry);
      setSelectedLogo(uklogo);
    } else {
      setSelectedLogo(singapore);
    }
  };

  const loadYears = async () => {
    const receivedYear = await getYears(yearCountry).then((res) => {
      return res.years;
    });
    console.log("Received year", receivedYear);
    setYearsOptions(receivedYear);
    setSelectedYear(receivedYear);
  };
  const loadYearQuarter = async () => {
    const receivedYearQuarter = await displayFilter(yearCountry).then((res) => {
      return res;
    });
    var period_data = receivedYearQuarter.period_data;
    var yearQuarter = [];
    /*for quarter year*/
    /*Menu keys defined for menu item and submenu item */
    var menuKeys = [
      ["13", "14", "15"],
      ["16", "17", "18", "19"],
      ['20', '21', '22']
    ];
    var quart = [];
    var years = [];
    var menuKeys = [];
    var subMenuItemKey = 12;/*set subMenuItemKey = 13 because others menu item key ended with 12 */
    for (const key in period_data) {
      years.push(key)
      quart.push(period_data[key].quarter);
      var subArr = [];
      for (var i = 0; i < period_data[key].quarter.length; i++) {
        subMenuItemKey = subMenuItemKey + 1;
        let subMenuItemKeyString = subMenuItemKey.toString();
        subArr.push(subMenuItemKeyString)
      }
      menuKeys.push(subArr)
    }

    var keysQuarter = {};
    for (let i = 0; i < quart.length; i++) {
      for (let j = 0; j < quart[i].length; j++) {
        if (quart[i][j] !== "") {
          Object.assign(keysQuarter, { [menuKeys[i][j]]: quart[i][j] });
        }
      }
    }
    for (let i = 0; i < years.length; i++) {
      yearQuarter.push({ [years[i]]: menuKeys[i] });
    }
    setQuarterYears(yearQuarter);
    setYears(years);
    setKeysForQuarter(keysQuarter);
  };
  const handleSelectedYearChange = (yearCountry) => {
    setLoading(true);
    setReportYear(yearCountry);
    if (yearCountry.includes("us_2021")) {
      setSelectedLogo(uslogo);
    } else if (yearCountry.includes("uk_2021")) {
      setSelectedLogo(uklogo);
    }
    navigate(`/${yearCountry}/top-categories`, { replace: true });
    setLoading(false);
  };

  useEffect(() => {
    updateSelectedYearCountry();
  }, []);

  useEffect(() => {
    const path = location.pathname;
    setCurrentPath(path);
  }, []);

  useEffect(() => {
    loadYears();
    loadYearQuarter();
  }, []);

  const content = (
    <div class="dropdwon-cus">
      <p onClick={confirm}>Logout</p>
    </div>
  );
  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  const [visible, setVisible] = useState(false);

  let userDetail = Cookies.get("user-info");
  userDetail = userDetail ? JSON.parse(userDetail) : userDetail;

  if (!userDetail) {
    return <Navigate to={process.env.REACT_APP_API_REPLACE_ENDPOINT} />;
    //return <Navigate to={replaceURLAddress} />;
  }

  const checkCSA = userDetail.user_permissions.platform_role_id;

  let reportDetails = localStorage.getItem("reports");

  let reportName = window.location.pathname.split("/")[0];
  reportDetails = JSON.parse(reportDetails);
  let reportsname = reportDetails && reportDetails.map((item) => item.report);

  const userInitials =
    userDetail.first_name.slice(0, 1) + userDetail.last_name.slice(0, 1);

  const handleMenuChange = (selectedKey, cat, selectedCat) => {
    SetActivekey(selectedKey)
    /*setting activity on google analytics*/
    const category = cat;
    const action = selectedCat;
    const label = cat;
    ReactGA.event({
      category, action, label
    })
  }
  const handleMenuClick = (bool, cat, selectedCat) => {
    setMenuSelected(bool);
    /*setting activity on google analytics*/
    const category = cat;
    const action = selectedCat;
    const label = cat;
    ReactGA.event({
      category, action, label
    })
  }

  const categoriesMenu = () => (
    <Menu selectable selectedKeys={activeKey}>
      <Menu.Item key={"1"} onClick={(item) => handleMenuChange([item.key], 'Top_Categories_Menu', 'Top_Categories_Time_View')}>
        {" "}
        <Link to={{ pathname: `/${reportYear}/top-categories/time` }}>
          {" "}
          <CalendarOutlined /> Time View{" "}
        </Link>{" "}
      </Menu.Item>
      <Menu.Item key={"2"} onClick={(item) => handleMenuChange([item.key], 'Top_Categories_Menu', 'Top_Categories_Retailer_View')}>
        {" "}
        <Link to={{ pathname: `/${reportYear}/top-categories/retailer` }}>
          {" "}
          <ShopOutlined /> Retailer View{" "}
        </Link>
      </Menu.Item>
      <Menu.Item key={"3"} onClick={(item) => handleMenuChange([item.key], 'Top_Categories_Menu', 'Top_Categories_Activity_View')}>
        {" "}
        <Link to={{ pathname: `/${reportYear}/top-categories/activity` }}>
          {" "}
          <TagOutlined /> Activity View{" "}
        </Link>
      </Menu.Item>
      <Menu.Item key={"4"} onClick={(item) => handleMenuChange([item.key], 'Top_Categories_Menu', 'Top_Categories_Location_View')}>
        {" "}
        <Link to={{ pathname: `/${reportYear}/top-categories/location` }}>
          {" "}
          <PushpinOutlined /> Location View{" "}
        </Link>
      </Menu.Item>
    </Menu>
  );

  const LocationMenu = () => (
    <Menu selectable selectedKeys={activeKey}>
      <Menu.Item key={"5"} onClick={(item) => handleMenuChange([item.key], 'Locations_Menu', 'Location_Time_View')}>
        {" "}
        <Link to={{ pathname: `/${reportYear}/locations/time  ` }}>
          {" "}
          <CalendarOutlined /> Time View{" "}
        </Link>{" "}
      </Menu.Item>
      <Menu.Item key={"6"} onClick={(item) => handleMenuChange([item.key], 'Locations_Menu', 'Location_Retailer_View')}>
        {" "}
        <Link to={{ pathname: `/${reportYear}/locations/retailer` }}>
          {" "}
          <ShopOutlined /> Retailer View{" "}
        </Link>{" "}
      </Menu.Item>
    </Menu >
  );

  const DriversMenu = () => (
    <Menu selectable selectedKeys={activeKey}>
      <Menu.Item key={"7"} onClick={(item) => handleMenuChange([item.key], 'Impulse_Drivers_Menu', 'Impulse_Drivers_Time_View')}>
        {" "}
        <Link to={{ pathname: `/${reportYear}/drivers/time` }}>
          {" "}
          <CalendarOutlined /> Time View{" "}
        </Link>{" "}
      </Menu.Item>
      <Menu.Item key={"8"} onClick={(item) => handleMenuChange([item.key], 'Impulse_Drivers_Menu', 'Impulse_Drivers_Retailer_View')}>
        {" "}
        <Link to={{ pathname: `/${reportYear}/drivers/retailer` }}>
          {" "}
          <ShopOutlined /> Retailer View{" "}
        </Link>{" "}
      </Menu.Item>
    </Menu >
  );

  const categoryReportsMenu = () => (
    <Menu selectable selectedKeys={activeKey}>
      <Menu.Item key={"9"} onClick={(item) => handleMenuChange([item.key], 'Categories_Reports_Menu', 'Categories_Reports_Top_Brands')}>
        {" "}
        <Link to={{ pathname: `/${reportYear}/category-reports/brands` }}>
          {" "}
          Top Brands{" "}
        </Link>{" "}
      </Menu.Item>
      <Menu.Item key={"10"} onClick={(item) => handleMenuChange([item.key], 'Categories_Reports_Menu', 'Categories_Reports_Top_Locations')}>
        {" "}
        <Link to={{ pathname: `/${reportYear}/category-reports/locations` }}>
          {" "}
          Top Location{" "}
        </Link>{" "}
      </Menu.Item>
      <Menu.Item key={"11"} onClick={(item) => handleMenuChange([item.key], 'Categories_Reports_Menu', 'Categories_Reports_Top_Drivers')}>
        {" "}
        <Link to={{ pathname: `/${reportYear}/category-reports/drivers` }}>
          {" "}
          Top Drivers{" "}
        </Link>{" "}
      </Menu.Item>
      <Menu.Item key={"12"} onClick={(item) => handleMenuChange([item.key], 'Categories_Reports_Menu', 'Categories_Reports_Top_Displays')}>
        {" "}
        <Link to={{ pathname: `/${reportYear}/category-reports/displays` }}>
          {" "}
          Top Displays{" "}
        </Link>{" "}
      </Menu.Item>
    </Menu >
  );

  const DisplaysMenu = () => (
    <Menu selectable selectedKeys={activeKey}>
      {years &&
        years.map((year) => {
          return (
            <Menu.SubMenu title={year}>
              {quarterYears &&
                quarterYears.map((objYear, index) => {
                  return (
                    objYear[year] &&
                    objYear[year].map((key, ind) => {
                      const yrQ = `${keysForQuarter[key]}_${year}`;
                      // console.log(key)
                      // console.log(yrQ)
                      return (
                        <Menu.Item
                          key={key}
                          onClick={(item) => handleMenuChange([item.key], 'Displays_Menu', `Displays/${yrQ}`)}
                        >
                          <Link
                            to={{ pathname: `/${reportYear}/displays/${yrQ}` }}
                          >
                            {keysForQuarter[key]} {year}
                          </Link>
                        </Menu.Item>
                      );
                    })
                  );
                })}
            </Menu.SubMenu>
          );
        })}
    </Menu >
  );

  return (
    <Layout>
      {console.log("yearz optionssssss", yearOptions)}
      <Header
        className="bottomHeader site-layout-background"
        style={{ position: "fixed", zIndex: 9999, width: "100%" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "unset",
          }}
        >
          <div class="left-header">
            <Link to={{ pathname: `/reports` }}>
              {" "}
              <div className="ITlogo" />{" "}
            </Link>
          </div>
          <div class="right-header">
            <div className="site-page-header-ghost-wrapper middle-header gx-mr-4">
              <Dropdown overlay={categoriesMenu}>
                <Link to={{ pathname: `/${reportYear}/top-categories` }}>
                  <Button
                    icon={<CalendarOutlined />}
                    onClick={() => handleMenuClick(false, "Top_Categories_Landing", "Top_Categories")}
                    type={
                      location.pathname.includes("top-categories")
                        ? "primary"
                        : ""
                    }
                  >
                    Top Categories
                    <DownOutlined />
                  </Button>{" "}
                </Link>
              </Dropdown>

              <Dropdown overlay={LocationMenu}>
                <Link to={{ pathname: `/${reportYear}/locations` }}>
                  <Button
                    icon={<CalendarOutlined />}
                    onClick={() => handleMenuClick(false, "Locations_Landing", "Locations")}
                    type={
                      location.pathname.includes("locations") &&
                        !location.pathname.includes("category-reports")
                        ? "primary"
                        : ""
                    }
                  >
                    Locations <DownOutlined />
                  </Button>{" "}
                </Link>
              </Dropdown>

              <Dropdown overlay={DriversMenu}>
                <Link to={{ pathname: `/${reportYear}/drivers` }}>
                  <Button
                    icon={<CalendarOutlined />}
                    onClick={() => handleMenuClick(false, "Impulse_Drivers_Landing", "Impulse_Drivers")}
                    type={
                      location.pathname.includes("drivers") &&
                        !location.pathname.includes("category-reports")
                        ? "primary"
                        : ""
                    }
                  >
                    Impulse Drivers <DownOutlined />
                  </Button>{" "}
                </Link>
              </Dropdown>

              <Dropdown overlay={DisplaysMenu}>
                <Link to={{ pathname: `/${reportYear}/displays` }}>
                  <Button
                    icon={<CalendarOutlined />}
                    onClick={() => handleMenuClick(false, "Displays_Landing", "Displays")}
                    type={
                      location.pathname.includes("displays") &&
                        !location.pathname.includes("category-reports")
                        ? "primary"
                        : ""
                    }
                  >
                    Displays <DownOutlined />
                  </Button>{" "}
                </Link>
              </Dropdown>

              {checkCSA !== 2 ? (
                <Dropdown overlay={categoryReportsMenu}>
                  <Link to={{ pathname: `/${reportYear}/category-reports` }}>
                    <Button
                      icon={<CalendarOutlined />}
                      onClick={() => handleMenuClick(false, "Categories_Reports_Landing", "Categories_Reports")}
                      type={
                        location.pathname.includes("category-reports")
                          ? "primary"
                          : ""
                      }
                    >
                      Category Reports <DownOutlined />
                    </Button>{" "}
                  </Link>
                </Dropdown>
              ) : (
                ""
              )}
            </div>

            {console.log("reportYears", reportYear, selectedLogo)}
            <div class="gx-d-flex">
              {/* {reportDetails.length > 1 ? (
                <div class="report-country">
                  <img class="country-flg" src={selectedLogo} />
                  <Select
                    value={reportYear}
                    onChange={handleSelectedYearChange}
                  >
                    {reportsname &&
                      reportsname.map((item, i) => (
                        <Option value={item.report_name} key={i}>
                          {" "}
                          <div> {item.report_display_name}</div>{" "}
                        </Option>
                      ))}
                  </Select>
                </div>
              ) : (
                <div class="report-country single-report">
                  {" "}
                  <img class="country-flg" src={selectedLogo} />{" "}
                  {_.upperCase(reportYear)}{" "}
                </div>
              )}
              <span>{reportName}</span> */}

              <Popover
                overlayClassName="user-dropdown"
                visible={visible}
                content={content}
                onVisibleChange={handleVisibleChange}
                trigger="click"
              >
                <Avatar size="large" style={{ backgroundColor: "#038FDE" }}>
                  {userInitials && userInitials}
                </Avatar>
              </Popover>
            </div>
          </div>
        </div>
      </Header>
      {loading ? (
        <Spin
          size="large"
          style={{ display: "flex", justifyContent: "center" }}
        />
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default AppHeader;
