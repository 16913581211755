const { crudData } = require('../../utils/apiService');


const getCategories = async (reportYear) => {
  const res = await crudData('/reports/category-reports/', 'GET', {}, {}, { report_name: reportYear }).then(res => res.json());
  return res;
};

const getReportCategoriesByBrand = async (reportYear, categoryName) => {
  const res = await crudData('/reports/category-reports/', 'POST', {}, { report_name: reportYear, filter_by: 'brands', category_name: categoryName }, {}).then(res => res.json());
  return res;
};

const getReportCategoriesByLocation = async (reportYear, categoryName) => {
  const res = await crudData('/reports/category-reports/', 'POST', {}, { report_name: reportYear, filter_by: 'locations', category_name: categoryName }, {}).then(res => res.json());
  return res;
};

const getReportCategoriesByDrivers = async (reportYear, categoryName) => {
  const res = await crudData('/reports/category-reports/', 'POST', {}, { report_name: reportYear, filter_by: 'drivers', category_name: categoryName }, {}).then(res => res.json());
  return res;
};

const getYears = async (reportYear) => {
  const res = await crudData('/users/report/year-info/', 'GET', {}, {}, { report_name: reportYear }).then(res => res.json());
  return res;
};

const displayFilter = async (reportYear) => {
  const res = await crudData('/reports/display-filters/', 'GET', {}, {}, { report_name: reportYear }).then(res => res.json());
  return res;
}




export {
  getCategories,
  getReportCategoriesByBrand,
  getReportCategoriesByDrivers,
  getReportCategoriesByLocation,
  getYears,
  displayFilter
};
