const locationTypes = {
    centerOfStore: ['Total Center of Store', 'Primary Aisle', 'Secondary Aisle', 'In-Aisle Displays'],
    outOfCenter: ['Total Out of Center', 'Endcaps', 'Main Lobby/ Entrance Displays', 'Main Walkway Displays', 'Perimeter',],
    displays: ['Total Displays', 'Endcaps', 'Shippers/Floor Stands', 'Island/Palettes'],
    checkout: ['Checkout']
}

module.exports = {
    locationTypes
}
