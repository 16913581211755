import { Select, Table, Spin, Row, Col, Card, PageHeader, Button, Breadcrumb, Empty, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { getCategories, getReportCategoriesByLocation, getYears } from '../../../services/category-reports';
import { createColumnsForTable } from '../category-reports-utils';
import { FilterOutlined, CalendarOutlined, ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons"
import { locationTypes } from '../constants';
import { Link, useLocation, useParams } from 'react-router-dom';
import './style.scss';
import _ from 'lodash';
import Cookies from 'js-cookie';
import NotFound from '../../../shared/not-found';
import ReactGA from "react-ga4";
const { Option } = Select;
// const yearsOptions = ['H1 2022', 'H2 2021']
const LocationReport = () => {
    let userDetail = Cookies.get('user-info');
    userDetail = JSON.parse(userDetail)
    const checkCSA = userDetail.user_permissions.platform_role_id;

    const [reportYear, setReportYear] = useState(window.location.pathname.split('/')[1]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState();
    const [selectedYear, setSelectedYear] = useState();
    const [locationData, setLocationData] = useState()
    const [centerOfStoreData, setCenterOfStoreData] = useState();
    const [displaysData, setDisplaysData] = useState();
    const [outOfCenterData, setoutOfCenterData] = useState();
    const [checkoutData, setCheckoutData] = useState();
    const [tableColumns, setTableColumns] = useState();
    const [loading, setLoading] = useState(true);
    const [islocationcos, setislocationcos] = useState(false);
    const [islocationooc, setislocationooc] = useState(false);
    const [reqdCol, setReqdcol] = useState()
    const [islocationdisplay, setislocationdisplay] = useState(false);
    const [yearsOptions, setYearsOptions] = useState([]);
    const [showNoRecords, setShowNoRecords] = useState(false)
    const [defaultSelectedYear, setDefaultSelectedYear] = useState()
    const handleCategoryChange = async () => {
        let currentSelectedYear;
        setLoading(true)
        if (selectedCategory) {
            const receivedLocationData = await getReportCategoriesByLocation(reportYear, selectedCategory).then(res => {
                if (res.sorting_order && !selectedYear) {
                    setYearsOptions(res.sorting_order)
                    setDefaultSelectedYear(res.sorting_order[0])
                }
                else {
                    // setSelectedYear(selectedYear)
                }
                if (!selectedYear) {
                    currentSelectedYear = res?.sorting_order?.[0];
                } else {
                    currentSelectedYear = selectedYear;
                }
                return res.result
            });
            setLocationData(receivedLocationData);
            let COSData = receivedLocationData && receivedLocationData.filter(item => locationTypes.centerOfStore.includes(item.Location));
            let OOCData = receivedLocationData && receivedLocationData.filter(item => locationTypes.outOfCenter.includes(item.Location));
            let displayData = receivedLocationData && receivedLocationData.filter(item => locationTypes.displays.includes(item.Location));
            displayData = constructSortedData(displayData)
            let checkoutData = receivedLocationData && receivedLocationData.filter(item => locationTypes.checkout.includes(item.Location));
            setCenterOfStoreData(COSData);
            setoutOfCenterData(OOCData);
            setDisplaysData(displayData);
            setCheckoutData(checkoutData)

            // const requiredColumns = ['Location', selectedYear];


            console.log("req Columns", reqdCol, selectedYear)
            const createdColumns = receivedLocationData && createColumnsForTable(receivedLocationData).filter(col => ['Location', currentSelectedYear].includes(col.dataIndex));

            if (createdColumns && createdColumns.length == 1) { setShowNoRecords(true); }
            else {
                setShowNoRecords(false)
                setTableColumns(createdColumns);
            }

            setLoading(false)
        }

    }

    const constructSortedData = (data) => {
        let order = ['Total Displays', 'Endcaps', 'Shippers/Floor Stands', 'Island/Palettes'];
        let SortedData = _.sortBy(data, function (obj) {
            return _.indexOf(order, obj.Location);
        });
        return SortedData
    }

    useEffect(() => {
        document.title = "Category Reports IT"
    }, [])

    const loadYears = async () => {
        const receivedYear = await getYears(reportYear).then(res => {
            return res
        });
        // const years = receivedYear.map(item=>{
        //     return [`H1 ${item}`, `H2 ${item}`]
        //   }).flat();

        const years = receivedYear.half_years;
        setYearsOptions(years)
        console.log("year options", yearsOptions)
        setSelectedYear(years[0])
    }


    const onInit = async () => {
        // loadYears();
        const receivedCategories = await getCategories(reportYear)
            .then(res => {
                return res.result
            });
        if (receivedCategories.length > 0) {
            console.log("get deeref", receivedCategories)
            setCategories(receivedCategories);
            setSelectedCategory(receivedCategories[0]);
            selectedCategory && handleCategoryChange()
        }
        else {
            setLoading(false)
        }
    }

    useEffect(() => {
        onInit()
    }, [])

    useEffect(() => {
        handleCategoryChange();
    }, [selectedCategory, selectedYear])

    const handleCategory = (selected_category) => {
        setLoading(true);
        setSelectedCategory(selected_category);
        setLoading(false);
        /*setting activity on google analytics*/
        const category = "Categories_Report_Top_Locations_Select_Category";
        const action = `CR_TL/${selectedYear ? selectedYear : ''} ${selectedYear ? '/' : ''} ${selected_category}`;
        const label = "Test_Categories_Report_Top_Locations_Select_Category";
        if (selected_category) {
            ReactGA.event({
                category, action, label
            })
        }
    }
    const handleYear = (year) => {
        if (year) {
            setSelectedYear(year);
            setReqdcol(['Location', year])
            setDefaultSelectedYear(year)
        }
        /*setting activity on google analytics*/
        const category = "Categories_Report_Top_Locations_Category_Period_Filter";
        const action = `CR_TL/${year}${selectedCategory ? '/' : ''}${selectedCategory ? selectedCategory : ''}`;
        const label = "Test_Categories_Report_Top_Locations_Category_Period_Filter";
        console.log(action)
        if (year) {
            ReactGA.event({
                category, action, label
            })
        }
    }

    const showLocationCOS = () => {
        setislocationcos(true);
    }

    const showLocationOOC = () => {
        setislocationooc(true);
    }

    const showLocationDisplay = () => {
        setislocationdisplay(true);
    }

    const handleCancelcos = () => {
        setislocationcos(false)
    }

    const handleCancelOOC = () => {
        setislocationooc(false)
    }
    const handleCancelDisplay = () => {
        setislocationdisplay(false)
    }

    let locale = {
        emptyText: (
            <Empty description="You may not have subscribed to this section of the report. Please try changing filters such as time periods.
            Feel free to reach your NAILBITER Client Service Contact if you need any support." />
        )
    };
    return (
        <>
            {checkCSA !== 2 ? <>
                <div className="site-page-header-ghost-wrapper" >

                    {/* <Breadcrumb>
                    <Breadcrumb.Item><Link to={{ pathname: `/reports` }}>Home</Link></Breadcrumb.Item>
                    <Breadcrumb.Item><Link to={{ pathname: `/${reportYear}/category-reports` }}>categories-reports</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Breadcrumb.Item>Top Location by Category</Breadcrumb.Item>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <PageHeader
                    title="Top Location by Category"
                    extra={[
                        <Link to={{ pathname: `/${reportYear}/top-categories` }} state={{ filterBasedOn: "top-category" }}><Button key="1" icon={<CalendarOutlined />} >Top Categories</Button> </Link>,
                        <Link to={{ pathname: `/${reportYear}/locationlanding` }} state={{ filterBasedOn: "location-landing" }}> <Button key="2" icon={<CalendarOutlined />}>Locations</Button></Link>,
                        <Link to={{ pathname: `/${reportYear}/driversLanding` }} state={{ filterBasedOn: "driver-timer" }}>  <Button key="3" icon={<CalendarOutlined />}>Impulse Driver</Button> </Link>,
                        <Link to={{ pathname: `/${reportYear}/displays` }} state={{ filterBasedOn: "displays" }}> <Button key="4" icon={<CalendarOutlined />}>Displays</Button></Link>,
                        checkCSA !== 2 ?  <Link  to={{ pathname:`/${reportYear}/category-reports`}}  state={{ filterBasedOn: "category-reports" }}> <Button key="5" type="primary" icon={<CalendarOutlined />}>Category Reports</Button></Link> : <></>
                    ]}
                >
                </PageHeader> */}
                    <div class="page-title">
                        <h1>Top Locations</h1>
                        <Breadcrumb>
                            <Breadcrumb.Item><Link class="back-arrow" to={{ pathname: `/${reportYear}/category-reports` }}><Button type='primary' shape="round" >Back</Button></Link></Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
                <div class="flex gx-mt-3 gx-flex-row-reverse gx-d-flex">
                    <Select
                        showSearch
                        allowClear
                        placeholder="Category"
                        optionFilterProp="children"
                        suffixIcon={<FilterOutlined />}
                        onChange={(cat) => handleCategory(cat)}
                        // defaultValue={selectedCategory}
                        value={selectedCategory}

                        style={{ width: 250, }}
                    // filterOption={(input, option) =>
                    //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                    >
                        {categories && categories.map((item, i) => <Option value={item} key={i}> {item}</Option>)}
                    </Select>



                    <Select
                        showSearch
                        allowClear
                        placeholder="Year"
                        optionFilterProp="children"
                        suffixIcon={<FilterOutlined />}
                        onChange={(year) => handleYear(year)}
                        value={defaultSelectedYear}
                        style={{ width: 250, }}
                        className="gx-mr-3"
                    >
                        {yearsOptions && yearsOptions.map((item, i) => <Option value={item} key={i}> {item}</Option>)}
                    </Select>
                </div>


                <div>

                </div>
                {loading ? <><Spin size='large' style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', minHeight: '450px', alignItems: 'center' }} /> </> : <>
                    {typeof (tableColumns) !== 'undefined' && !showNoRecords ?
                        <Row>
                            <Col span={8}>
                                <Card
                                    hoverable
                                    className="centerStore"
                                    title="Center Of Stores"
                                    style={{ marginTop: 10, }}
                                    onClick={showLocationCOS}
                                    cover={<img src='../../images/img1.png' style={{ width: '98%', marginLeft: '5px' }} />}
                                >

                                    {tableColumns && <Table
                                        scroll={{ x: 80 }}
                                        style={{ tableLayout: 'auto', width: '100%' }}
                                        className="tableStructure"
                                        key={tableColumns}
                                        columns={tableColumns}
                                        rowClassName={(record, index) => record.Location === 'Total Center of Store' ? 'table-row-dark' : ''}
                                        dataSource={centerOfStoreData}
                                        loading={{ indicator: <Spin size="large" />, spinning: loading }}
                                        pagination={centerOfStoreData.length > 10 ? { defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] } : false}
                                    />
                                    }
                                </Card>

                                <Modal className="center-store-cus top-lc-cus" title="Center Of Stores" visible={islocationcos} onCancel={handleCancelcos} footer={null}>
                                    <Card
                                        hoverable
                                        className="centerStore"
                                        style={{ marginTop: 10, }}
                                        onClick={showLocationCOS}
                                        cover={<img src='../../images/img1.png' style={{ width: '98%', marginLeft: '5px' }} />}
                                    >
                                        {<Table
                                            scroll={{ x: 80 }}
                                            style={{ tableLayout: 'auto', width: '100%' }}
                                            className="tableStructure"
                                            key={tableColumns}
                                            columns={tableColumns}
                                            rowClassName={(record, index) => record.Location === 'Total Center of Store' ? 'table-row-dark' : ''}
                                            dataSource={centerOfStoreData}
                                            loading={{ indicator: <Spin size="large" />, spinning: loading }}
                                            pagination={centerOfStoreData.length > 10 ? { defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] } : false}
                                        />}
                                    </Card>
                                </Modal>

                            </Col>

                            <Col span={8}> <Card
                                hoverable
                                className="OurStore"
                                title="Out Of Center"
                                onClick={showLocationOOC}
                                style={{ marginTop: 10, }}
                                cover={<img src='../../images/img2.png' style={{ width: '98%', marginLeft: '5px' }} />}
                            >
                                {tableColumns && <Table
                                    style={{ tableLayout: 'auto', width: '100%' }}
                                    className="tableStructure"
                                    key={tableColumns}
                                    columns={tableColumns}
                                    locale={locale}
                                    rowClassName={(record, index) => record.Location === 'Total Out of Center' ? 'table-row-dark' : ''}
                                    dataSource={outOfCenterData}
                                    loading={{ indicator: <Spin size="large" />, spinning: loading }}
                                    pagination={outOfCenterData.length > 10 ? { defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] } : false}
                                />


                                }
                                <br></br>

                                <Table
                                    style={{ tableLayout: 'auto', width: '100%' }}
                                    className="tableStructure"
                                    key={tableColumns}
                                    columns={tableColumns}
                                    dataSource={checkoutData}
                                    // rowClassName={(record, index) => index === 0 ? 'table-row-dark' : ''}
                                    loading={{ indicator: <Spin size="large" />, spinning: loading }}
                                    pagination={checkoutData.length > 10 ? { defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] } : false}
                                />
                            </Card>

                                <Modal className="outoff-center-cus top-lc-cus" title="Out Of Center" visible={islocationooc} onCancel={handleCancelOOC} footer={null}>
                                    <Card
                                        hoverable
                                        className="OurStore"
                                        style={{ marginTop: 10, }}
                                        cover={<img src='../../images/img2.png' style={{ width: '98%', marginLeft: '5px' }} />}
                                    >
                                        {<Table
                                            style={{ tableLayout: 'auto', width: '100%' }}
                                            className="tableStructure"
                                            key={tableColumns}
                                            columns={tableColumns}
                                            locale={locale}
                                            dataSource={outOfCenterData}
                                            rowClassName={(record, index) => record.Location === 'Total Out of Center' ? 'table-row-dark' : ''}
                                            loading={{ indicator: <Spin size="large" />, spinning: loading }}
                                            pagination={outOfCenterData.length > 10 ? { defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] } : false}
                                        />}
                                        <br></br>
                                        <Table
                                            style={{ tableLayout: 'auto', width: '100%' }}
                                            className="tableStructure"
                                            key={tableColumns}
                                            columns={tableColumns}
                                            locale={locale}
                                            // rowClassName={(record, index) => index === 0 ? 'table-row-dark' : ''}
                                            dataSource={checkoutData}
                                            loading={{ indicator: <Spin size="large" />, spinning: loading }}
                                            pagination={checkoutData.length > 10 ? { defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] } : false}
                                        />
                                    </Card>
                                </Modal>

                            </Col>


                            <Col span={8}> <Card
                                hoverable
                                className="display"
                                title="Displays"
                                onClick={showLocationDisplay}
                                style={{ marginTop: 10, }}
                                cover={<img src='../../images/img3.png' style={{ width: '98%', marginLeft: '5px' }} />}
                            >
                                {tableColumns && <Table
                                    scroll={{ x: 80 }}
                                    style={{ tableLayout: 'auto', width: '100%' }}
                                    className="tableStructure"
                                    key={tableColumns}
                                    // locale={locale}
                                    rowClassName={(record, index) => record.Location === 'Total Displays' ? 'table-row-dark' : ''}
                                    columns={tableColumns}
                                    dataSource={displaysData}
                                    loading={{ indicator: <Spin size="large" />, spinning: loading }}
                                    pagination={displaysData.length > 10 ? { defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] } : false}
                                />}
                            </Card>

                                <Modal className="display-cus top-lc-cus" title="Displays" visible={islocationdisplay} onCancel={handleCancelDisplay} footer={null}>
                                    <Card
                                        hoverable
                                        className="display"
                                        style={{ marginTop: 10, }}
                                        cover={<img src='../../images/img3.png' style={{ width: '98%', marginLeft: '5px' }} />}
                                    >
                                        {<Table
                                            scroll={{ x: 80 }}
                                            style={{ tableLayout: 'auto', width: '100%' }}
                                            className="tableStructure"
                                            key={tableColumns}
                                            rowClassName={(record, index) => record.Location === 'Total Displays' ? 'table-row-dark' : ''}
                                            locale={locale}
                                            columns={tableColumns}
                                            dataSource={displaysData}
                                            loading={{ indicator: <Spin size="large" />, spinning: loading }}
                                            pagination={displaysData.length > 10 ? { defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] } : false}
                                        />}
                                    </Card>
                                </Modal>

                            </Col>


                        </Row> : (displaysData.length == 0 || showNoRecords) && <div class="no-record-found"><img src="../../images/no-data.png" /><h4 class="nodata-dis">You may not have subscribed to this section of the report. Please try changing filters such as time periods.<br></br> Feel free to reach your NAILBITER Client Service Contact if you need any support.</h4></div>}</>}
            </> : <NotFound />}




        </>
    );
}

export default LocationReport;