import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Alert } from 'antd';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

// export default () => <Spin indicator={antIcon} />;

const spinner = () => (
    <Spin size="large"  tip="Loading..."  style={{ display:'flex', justifyContent:'center', minHeight: '450px', alignItems:'center' }} >
    </Spin>
  );

  export {spinner}