import { Layout } from "antd";
import { AppContent, AppHeader, AppSidebar } from "../components/common/index";
import "../layouts/style.css"
import "antd/dist/antd.css";

const DefaultLayout = () => {
  return (
    <div>
      <Layout key={0} >
        {/* <AppSidebar hasSider /> */}
        <Layout key={2} className="site-layout" >
          <AppHeader />
          <AppContent className="site-layout" style={{ padding: '0 50px', marginTop: 64 }} />
        </Layout>
      </Layout>
    </div>
  );
};
export default DefaultLayout;
